



























import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { DropdownItem } from '@/models/dropdownItem';
import { generateGuid } from '../directives/click-outside';
import DropdownListItem from './dropdown-list-item.vue';

@Component({ components: { DropdownListItem }, name: 'Dropdown' })
export default class Dropdown extends Vue {
    @Prop() value!: string | number;

    @Prop() name!: string;

    @Prop() options!: Array<DropdownItem>;

    @Prop() showMobileInfo!: boolean;

    id = '';

    dirty = false;

    selectedValue: DropdownItem | null = null;

    focused = false;

    selected: DropdownItem | null = null;

    hovered: DropdownItem | null = null;

    created (): void {
        this.id = generateGuid();
    }

    infoClicked (): void {
        this.hovered = this.selected;
    }

    scrollEvent (e: Event): void {
        if ((e.target as HTMLElement).className === 'scroll' && this.focused) {
            this.focused = false;
            (this.$refs.input as HTMLElement).blur();
            document.removeEventListener('scroll', this.scrollEvent);
        }
    }

    findItem (items: Array<DropdownItem>, value: string | number): DropdownItem | null {
        if (!items) return null;
        for (let i = 0; i < items.length; i += 1) {
            if (items[i].value === value) return items[i];
            const item = this.findItem(items[i].children ?? [], value);
            if (item) return item;
        }
        return null;
    }

    findItemParent (items: Array<DropdownItem>, item: DropdownItem, parent?: DropdownItem): DropdownItem | null {
        if (!items) return null;
        if (items.find((a) => a === item)) return parent ?? null;
        for (let i = 0; i < items.length; i += 1) {
            const found = this.findItemParent(items[i].children ?? [], item, items[i]);
            if (found) return found;
        }
        return null;
    }

    hoveredItem (item: DropdownItem | null): void {
        this.hovered = item;
    }

    clickedOutside (): void {
        this.hovered = null;
        this.focused = false;
        this.$emit('hoveredItem', null);
    }

    downPressed (e: KeyboardEvent): void {
        const options = this.hovered ? this.hovered.children ?? [] : this.options;

        if (!this.selected) {
            this.selected = options[0];
        } else {
            const found = options.find((item) => item === this.selected);
            if (!found) return;
            const index = options.indexOf(found);
            if (index < options.length - 1) {
                this.selected = options[index + 1];
            }
        }
        this.scrollToItem();
        e.preventDefault();
        e.stopPropagation();
    }

    scrollToItem (): void {
        if (this.selected) {
            this.$emit('hoveredItem', this.selected);
            const el = this.$refs[this.selected.value ?? ''];
            if (el) {
                const topPos = (el as [HTMLElement])[0].offsetTop;
                (this.$refs.dropdown as HTMLElement).scrollTop = topPos - 24;
            }
        }
    }

    upPressed (e: KeyboardEvent): void {
        const options = this.hovered ? this.hovered.children ?? [] : this.options;
        if (!this.selected) {
            this.selected = options[0];
        } else {
            const found = options.find((item) => item === this.selected);
            if (!found) return;
            const index = options.indexOf(found);
            if (index > 0) {
                this.selected = options[index - 1];
            }
        }
        this.scrollToItem();
        e.preventDefault();
        e.stopPropagation();
    }

    rightPressed (e: KeyboardEvent): void {
        if (this.selected && this.selected.children?.length) {
            this.hovered = this.selected;
            this.selected = this.hovered.children![0];
        }
        e.preventDefault();
        e.stopPropagation();
    }

    leftPressed (e: KeyboardEvent): void {
        if (this.selected) {
            const parent = this.findItemParent(this.options, this.selected);
            if (!parent) return;
            this.selected = parent;
            const parentParent = this.findItemParent(this.options, parent);
            this.hovered = parentParent;
        }
        e.preventDefault();
        e.stopPropagation();
    }

    enterPressed (): void {
        if (this.selected) {
            this.selectedValue = this.selected;
        }
        this.focused = false;
        (this.$refs.input as HTMLElement).blur();
    }

    tabPressed (): void {
        this.focused = false;
    }

    itemSelected (item: DropdownItem):void {
        this.selected = item;
        this.selectedValue = item;
        this.focused = false;
        this.hovered = null;
        this.$nextTick(() => {
            this.$emit('change');
        });
    }

    caretMoved (): void {
        this.dirty = true;
        this.focused = !this.focused;
        // this.showList();
    }

    @Watch('focused')
    focusedChanged (): void {
        if (this.focused) {
            document.addEventListener('scroll', this.scrollEvent, true);
        } else {
            document.removeEventListener('scroll', this.scrollEvent);
        }
    }

    @Watch('selectedValue')
    selectedValueChanged (): void {
        this.dirty = true;
        this.hovered = null;
        this.$emit('input', this.selectedValue ? this.selectedValue.value : null);
    }

    @Watch('value', { immediate: true })
    valueChanged (): void {
        const item = this.findItem(this.options, this.value);
        this.selectedValue = item;
        this.selected = item;
    }

    @Watch('options')
    optionsChanged (): void {
        if (!this.findItem(this.options, this.value)) this.itemSelected(this.options[0]);
    }

    @Watch('hovered')
    hoveredChanged (): void {
        this.$emit('hoveredItem', this.hovered);
    }
}
