










































import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

import DataService from '@/services/dataService';
import CcgDataType from '@/models/ccgDataTypes';
import TrustDataType from '@/models/trustDataTypes';
import Speciality from '@/models/speciality';

@Component
export default class Rates extends Vue {
    @Prop()
    dataType!: CcgDataType | TrustDataType;

    @Prop()
    selectedSpeciality!: Speciality;

    @Prop()
    areaType!: 'trust' | 'ccg' | 'stp';

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedDate!: string;

    dataService: DataService | null = null;

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
    }

    formatNumber (value: number): string {
        if (Number.isNaN(value)) return '0';
        return `${(Math.round(Number(value) * 100) / 100).toLocaleString()}`;
    }

    get metricText (): string {
        let data = '';
        switch (this.dataType) {
        case CcgDataType.Waiting: data = 'people currently waiting'; break;
        case CcgDataType.WaitingPer100000: data = 'people currently waiting per 100,000'; break;
        case TrustDataType.PercentWithin14Days: data = 'of cancer patients seen within 14 days'; break;
        case TrustDataType.PercentWithin18WeeksAdmitted: data = 'admitted referrals seen within 18 weeks'; break;
        case TrustDataType.PercentWithin18WeeksNonAdmitted: data = 'non-admitted referrals seen within 18 weeks'; break;
        default: data = ''; break;
        }
        return data + (this.selectedSpeciality !== Speciality.Total ? ` (${this.selectedSpeciality})` : '');
    }

    get areaName (): string {
        if (!this.selectedArea || !this.dataService) return 'England';
        if (this.areaType === 'stp') return this.dataService.getAreaNameFromStp(this.selectedArea) ?? this.selectedArea;
        return this.dataService?.getAreaNameFromCcg(this.selectedArea) ?? this.dataService?.getRegionNameFromRegionCode(this.selectedArea) ?? this.selectedArea;
    }

    get isHigherBetter (): boolean {
        return this.dataService!.isHigherBetter(CcgDataType.Waiting);
    }

    get numberWaiting (): number {
        return this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.selectedDate, CcgDataType.Waiting, this.selectedSpeciality);
    }

    get previousMonthWaiting (): number {
        return this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.previousDynamicDate, CcgDataType.Waiting, this.selectedSpeciality);
    }

    get numberWaitingAtLockdown (): number {
        return this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.lockdownMonth, CcgDataType.Waiting, this.selectedSpeciality);
    }

    get hiddenNeeds (): number {
        return Math.round(this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.selectedDate, CcgDataType.Hidden, this.selectedSpeciality));
    }

    get previousMonthHidden (): number {
        return Math.round(this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.previousDynamicDate, CcgDataType.Hidden, this.selectedSpeciality));
    }

    get hiddenNeedsAtLockdown (): number {
        return Math.round(this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.lockdownMonth, CcgDataType.Hidden, this.selectedSpeciality));
    }

    get hiddenNeedsAtSixMonthsIntoLockdown (): number {
        return Math.round(this.dataService!.getAreaTotalForDate(this.areaType, this.selectedArea, this.sixMonthsIntoLockdown, CcgDataType.Hidden, this.selectedSpeciality));
    }

    get numberWaitingMonthlyChange (): number {
        return ((this.numberWaiting / this.previousMonthWaiting) - 1) * 100;
    }

    get numberWaitingSinceLockdown (): number {
        return ((this.numberWaiting / this.numberWaitingAtLockdown) - 1) * 100;
    }

    get hiddenNeedsMonthlyChange (): number {
        return ((this.hiddenNeeds / this.previousMonthHidden) - 1) * 100;
    }

    get hiddenNeedsSinceLockdown (): number {
        return ((this.hiddenNeeds / this.hiddenNeedsAtLockdown) - 1) * 100;
    }

    get hiddenNeedsSixMonthsIntoLockdown (): number {
        return ((this.hiddenNeeds / this.hiddenNeedsAtSixMonthsIntoLockdown) - 1) * 100;
    }

    get lockdownMonth (): string {
        return '2020-03-01';
    }

    get sixMonthsIntoLockdown (): string {
        return '2020-09-01';
    }

    get previousDynamicDate (): string {
        const index = this.dataService?.dates.indexOf(this.selectedDate);
        if (!index || index <= 0) return this.selectedDate;
        return this.dataService!.dates[index - 1];
    }

    get disableHiddenNeedsChange (): boolean {
        return this.selectedDate < this.lockdownMonth;
    }
}
