/* eslint-disable no-shadow */
enum CcgDataType {
    Waiting = 'Waiting',
    Unmet = 'Unmet',
    Hidden = 'Hidden',
    WaitingPer100000 = 'WaitingPer100000',
    UnmetPer100000 = 'UnmetPer100000',
    HiddenPer100000 = 'HiddenPer100000',
}

export default CcgDataType;
