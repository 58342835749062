import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Insight from '../views/Insight.vue';
import Scatter from '../views/Scatter.vue';
import Terms from '../views/Terms.vue';
import Projections from '../views/Projections.vue';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Dashboard',
        component: Home,
    },
    {
        path: '/insight',
        name: 'Insight',
        component: Insight,
    },
    {
        path: '/projections',
        name: 'Projections',
        component: Projections,
    },
    {
        path: '/scatter',
        name: 'Associations',
        component: Scatter,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
    },
    {
        path: '/dataAndMethod',
        name: 'Data sources and definitions',
        component: About,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
