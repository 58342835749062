





























import {
    MapChart, MapOptions, MapPos, Marker,
} from '@lcp/map-chart';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DataService from '@/services/dataService';
import TrustDataType from '@/models/trustDataTypes';
import CcgDataType from '@/models/ccgDataTypes';
import dayjs from 'dayjs';
import Speciality from '@/models/speciality';
import Dropdown from '@/components/dropdown.vue';
import { DropdownItem } from '@/models/dropdownItem';

@Component({
    components: { Dropdown },
})
export default class MapComponent extends Vue {
    @Prop()
    dataType!: TrustDataType | CcgDataType;

    @Prop()
    selectedDate!: string;

    @Prop()
    selectedSpeciality!: Speciality;

    @Prop()
    areaType!: 'ccg' | 'trust' | 'stp';

    @Prop()
    selectedArea!: string | null;

    dataService: DataService | null = null

    mapLoaded = false;

    selectedRegion: string | null = null;

    hoveredArea: string | null = null;

    hoveredTrust: string | null = null;

    zoomLevel = 1;

    emitUpdate = true;

    trustMetric = TrustDataType.PercentWithin18WeeksAdmitted;

    functionToRunOnLoad: (() => void) | null = null;

    get formattedDate (): string {
        return dayjs(this.selectedDate).format('MMM YYYY');
    }

    get mapOptions (): MapOptions | null{
        if (!this.dataService) return null;
        return {
            topoJsonSettings: {
                jsonPath: '/ccg2021.json',
                featureCollectionName: 'collection',
                areaPropertyName: 'CCG21CD',
            },
            display: {
                gradient: false,
                unselectedColour: 'rgb(124, 124, 124)',
                defaultColour: 'rgb(150, 150, 150)',
                colourRange: this.dataService.colourRange,
                // hiddenAreas: ['Wales', 'Scotland', 'Northen Ireland'],
                height: 30,
                width: 30,
            },
            areaGroups: {
                hasRegions: true,
                getRegionForArea: (areaCode) => this.dataService!.getRegionCodeFromAreaCode(areaCode, this.areaType),
            },
            interactive: {
                allowPan: true,
                allowZoom: true,
                allowSelectArea: this.areaType === 'ccg',
                disabledAreas: ['Scotland', 'Wales', 'Northen Ireland'],
            },
            extents: {
                extentsFunction: this.extentsFunction,
            },
            events: {
                loading: (loading: boolean) => {
                    this.mapLoaded = !loading;
                    if (!loading && this.functionToRunOnLoad) {
                        this.functionToRunOnLoad();
                    }
                },
                areaSelected: (area: string[]) => {
                    if (!this.emitUpdate) return;
                    this.selectArea(area.length ? area[0] : null);
                },
                regionSelected: (region: string[]) => {
                    if (!this.emitUpdate) return;
                    this.selectRegion(region.length ? region[0] : null);
                },
                areaHovered: (area: string | null) => {
                    this.hoveredArea = area;
                },
                mapPositionChanged: (position: MapPos) => {
                    this.zoomLevel = position.zoom / 50;
                },
                markerHovered: (e: MouseEvent, marker: Marker | null) => {
                    this.hoveredTrust = marker?.id ?? null;
                },
                markerClicked: (e: MouseEvent, marker: Marker) => {
                    const areaCode = this.getAreaByLatLong(marker.latitude, marker.longitude);
                    if (marker.id === this.selectedArea) {
                        (this.$refs.map as MapChart).deselect();
                        this.$emit('trustSelected', null);
                        return;
                    }

                    if (areaCode) {
                        (this.$refs.map as MapChart).selectAreas([areaCode]);
                    }
                    this.$emit('trustSelected', marker.id);
                },
            },
        };
    }

    get mapMarkers (): Array<Marker> {
        if (!this.mapLoaded || this.areaType === 'ccg' || this.areaType === 'stp') {
            return [{
                longitude: 0,
                latitude: 0,
            }];
        }
        const radiusSize = Math.max(1.5 - this.zoomLevel, 0.1);
        const data = this.dataService!.getDataGroupedByTrust(this.trustMetric, this.selectedDate, this.selectedSpeciality);
        return Object.values(this.dataService!.trustDetailsByTrust).map((a) => ({
            id: a.providerCode,
            longitude: Number(a.longitude),
            latitude: Number(a.latitude),
            radius: a.providerCode === this.selectedArea ? radiusSize * 1.5 : radiusSize,
            hoveredRadius: radiusSize * 1.1,
            strokeWidth: a.providerCode === this.selectedArea ? 0.1 : 0,
            // fillColour: '#003479',
            fillColour: (this.$refs.map as MapChart).getValueColour(data?.[a.providerCode]),
        }));
    }

    get mapData (): Record<string, number> | undefined {
        if (this.areaType === 'trust') {
            return this.dataService?.getDataGroupedByTrust(this.trustMetric, this.selectedDate, this.selectedSpeciality);
        }
        if (this.areaType === 'stp') {
            return this.dataService?.getStpDataGroupedByAreaCode(this.dataType as CcgDataType, this.selectedDate, this.selectedSpeciality);
        }
        return this.dataService?.getCcgDataGroupedByAreaCode(this.dataType as CcgDataType, this.selectedDate, this.selectedSpeciality);
    }

    get extents (): [number, number, number ] {
        if (!this.dataService) return [0, 0, 0];
        if (this.areaType === 'stp') return this.dataService.getMinMaxForStp(this.dataType as CcgDataType, this.selectedSpeciality).reverse() as [number, number, number];
        if (this.areaType === 'trust') return this.dataService.getMinMaxForTrustDataType(this.trustMetric, this.selectedSpeciality).reverse() as [number, number, number];
        return this.dataService.getMinMaxForCcgDataType(this.dataType as CcgDataType, this.selectedSpeciality).reverse()as [number, number, number];
    }

    get metricText (): string {
        return this.dataService!.getDataTypeLabel(this.dataType);
    }

    get trustOptions (): Array<DropdownItem> {
        return [
            { name: 'Admitted patients', value: TrustDataType.PercentWithin18WeeksAdmitted },
            { name: 'Non-admitted patients', value: TrustDataType.PercentWithin18WeeksNonAdmitted },
            { name: 'Cancer patients', value: TrustDataType.PercentWithin14Days },
        ];
    }

    get isCancerSelected (): boolean {
        return this.trustMetric === TrustDataType.PercentWithin14Days;
    }

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
        this.$nextTick(() => {
            this.mapLoaded = true;
        });
    }

    extentsFunction (): [number, number, number] {
        if (!this.mapData || !this.dataService) return [0, 0, 0];
        return this.extents;
    }

    selectAreaOnMap (areaCode: string | null): void {
        this.emitUpdate = false;
        if (!this.$refs.map) {
            this.functionToRunOnLoad = () => {
                this.selectAreaOnMap(areaCode);
            };
            return;
        }
        (this.$refs.map as MapChart).selectAreas(areaCode ? [areaCode] : []);
        this.emitUpdate = true;
    }

    selectRegionOnMap (regionCode: string | null): void {
        this.emitUpdate = false;
        if (!this.$refs.map) {
            this.functionToRunOnLoad = () => {
                this.selectRegionOnMap(regionCode);
            };
            return;
        }
        (this.$refs.map as MapChart).selectRegions(regionCode ? [regionCode] : []);
        this.emitUpdate = true;
    }

    selectArea (areaCode: string | null): void {
        const area = areaCode ? this.dataService!.getCcgCodeFromAreaCode(areaCode) : null;
        this.$emit('areaSelected', area);
    }

    selectRegion (regionCode: string | null): void {
        if (this.areaType === 'ccg' || this.areaType === 'trust') {
            this.selectedRegion = regionCode;
            this.$emit('areaSelected', regionCode);
            return;
        }
        this.selectedRegion = regionCode ?? 'stp';
        this.$emit('areaSelected', regionCode ?? 'stp');
    }

    deselectArea (): void {
        (this.$refs.map as MapChart).deselect();
    }

    getTrustName (trustId: string): string {
        return this.dataService!.getTrustNameFromTrustId(trustId) ?? trustId;
    }

    getAreaName (areaCode: string): string {
        return this.dataService!.getAreaNameFromAreaCode(areaCode, this.areaType);
    }

    getAreaByLatLong (lat: number, long: number): string {
        return (this.$refs.map as MapChart).getAreaByLatLong(lat, long);
    }

    formatValue (val: number): string {
        return (Math.round(val * 100) / 100).toLocaleString();
    }

    resize (): void {
        (this.$refs.map as MapChart).resize();
    }
}
