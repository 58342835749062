import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import MapChart from '@lcp/map-chart';

import {
    faHome, faExpandAlt, faChartScatter, faTriangle, faInfo,
    faInfoCircle, faEnvelope, faTimes, faCaretDown, faChevronRight, faChevronDown,
    faChevronLeft, faNewspaper, faAngleDoubleLeft, faAngleDoubleRight, faArrowLeft, faBars,
    faFileAlt, faUserFriends, faQuestionCircle, faChild, faTable, faCalendar, faCalendarCircleUser,
    faExclamation, faFaceDotted, faEyeSlash, faDisease, faSkeleton, faStomach, faHandDots, faBone,
    faCrutches, faStarOfLife, faUsers, faVirusCovid, faMoneyBillWave, faBadgePercent, faLineChart,
} from '@fortawesome/pro-regular-svg-icons';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './highchartsConfig';
import store from './store';
import router from './router';
import App from './App.vue';

import './directives/focus';
import clickOutside from './directives/click-outside';

library.add(faHome, faExpandAlt, faChartScatter, faTriangle, faInfo, faCaretDown, faChevronRight,
    faChevronLeft, faDisease, faSkeleton, faStomach, faHandDots,
    faBone, faChild, faMoneyBillWave, faBadgePercent, faLineChart,
    faNewspaper, faInfoCircle, faEnvelope, faTimes, faTwitter, faLinkedin, faAngleDoubleLeft,
    faAngleDoubleRight, faExclamation, faFaceDotted, faEyeSlash, faStarOfLife,
    faArrowLeft, faBars, faFileAlt, faUserFriends, faQuestionCircle, faChild, faTable, faChevronDown,
    faCalendar, faCalendarCircleUser, faCrutches, faUsers, faVirusCovid);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(MapChart);
Vue.use(clickOutside);

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
