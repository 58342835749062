















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ScatterChartData } from '@/models/scatterChartData';
import Dropdown from '@/components/dropdown.vue';
import ScatterChart from '@/components/charts/scatterChart';
import DataService from '@/services/dataService';
import CcgDataType from '@/models/ccgDataTypes';
import { CcgMapping } from '@/models/ccgMapping';
import TrustDataType from '@/models/trustDataTypes';
import Speciality from '@/models/speciality';
import { DropdownItem } from '@/models/dropdownItem';

@Component({
    components: {
        Dropdown,
    },
    props: {
        path: String,
    },
})
export default class Analysis extends Vue {
    scatterChart: ScatterChart | null = null;

    dataService: DataService | null = null;

    expanded = false;

    xAxis: CcgDataType | keyof CcgMapping = CcgDataType.WaitingPer100000;

    xAxisDomainData: { [area: string]: number } = {};

    xAxisDomainDataCompare: { [area: string]: number } = {};

    yAxis: CcgDataType | keyof CcgMapping = 'population';

    viewType = 'scatter';

    xAxisTotal = 'value';

    yAxisTotal = 'value';

    dotSize = 'default';

    category = 'cluster'

    yAxisDomainData: { [area: string]: number } = {};

    yAxisDomainDataCompare: { [area: string]: number } = {};

    isCollapsed = true;

    hoveredY = '';

    hoveredX = '';

    isLoaded = false;

    selectedSpeciality: Speciality = Speciality.Total;

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
        this.isLoaded = true;
        this.$nextTick(() => {
            this.scatterChart = new ScatterChart();
            this.scatterChart.initalise(
                    this.$refs.chart as HTMLElement,
                    this.$refs.tooltip as HTMLElement,
                    this.chartData,
                    this.xAxisTitle,
                    this.yAxisTitle,
                    this.xAxisArrowText,
                    this.yAxisArrowText,
                    this.axisUnit(this.xAxis),
                    this.axisUnit(this.yAxis),
            );
        });
    }

    @Watch('xAxis')
    @Watch('treatmentType')
    @Watch('selectedSpeciality')
    @Watch('xAxisYear')
    xAxisChanged (): void {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    @Watch('xAxisTotal')
    @Watch('dotSize')
    @Watch('category')
    xAxisTotalChanged (): void {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    @Watch('yAxisTotal')
    yAxisTotalChanged (): void {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    get xAxisTitle (): string {
        return `${this.axisTitle(this.xAxis)}`;
    }

    get yAxisTitle (): string {
        return `${this.axisTitle(this.yAxis)}`;
    }

    xIsHovered (metric: string): void {
        this.hoveredX = metric;
    }

    yIsHovered (metric: string): void {
        this.hoveredY = metric;
    }

    @Watch('yAxis')
    @Watch('yAxisYear')
    async yAxisChanged (): Promise<void> {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    get xAxisArrowText (): string {
        if (this.xAxis === 'population') return '';
        if (this.xAxis === 'imdDecile') return 'Better';
        if (this.xAxis === 'medianAge') return '';

        return (this.dataService?.isHigherBetter(this.xAxis as CcgDataType)) ? 'Better' : 'Worse';
    }

    get yAxisArrowText (): string {
        if (this.yAxis === 'population') return '';
        if (this.xAxis === 'imdDecile') return 'Better';
        if (this.xAxis === 'medianAge') return '';
        return (this.dataService?.isHigherBetter(this.yAxis as CcgDataType)) ? 'Better' : 'Worse';
    }

    get specialityDropdown (): Array<DropdownItem> {
        return [
            { name: 'All', value: Speciality.Total, icon: 'fa-star-of-life' },
            { name: 'Dermatology', value: Speciality.Dermatology, icon: 'fa-hand-dots' },
            { name: 'Rheumatology', value: Speciality.Rheumatology, icon: 'fa-skeleton' },
            { name: 'Gastroenterology', value: Speciality.Gastroenterology, icon: 'fa-stomach' },
            { name: 'Orthopaedics', value: Speciality.Orthopaedics, icon: 'fa-crutches' },
        ];
    }

    get axisOptions (): Array<DropdownItem > {
        return [
            { name: 'Waiting list', value: CcgDataType.Waiting, icon: 'fa-calendar' },
            {
                name: 'Waiting list - hidden needs ', value: CcgDataType.Hidden, icon: 'fa-calendar', secondaryIcon: 'fa-eye-slash',
            },
            {
                name: 'Waiting list - unmet needs', value: CcgDataType.Unmet, icon: 'fa-calendar', secondaryIcon: 'fa-exclamation',
            },
            { name: 'Waiting list per 100,000', value: CcgDataType.WaitingPer100000, icon: 'fa-calendar-circle-user' },
            {
                name: 'Waiting list per 100,000 - hidden needs', value: CcgDataType.HiddenPer100000, icon: 'fa-calendar-circle-user', secondaryIcon: 'fa-eye-slash',
            },
            {
                name: 'Waiting list per 100,000 - unmet needs', value: CcgDataType.UnmetPer100000, icon: 'fa-calendar-circle-user', secondaryIcon: 'fa-exclamation',
            },
            { name: 'Population', value: 'population', icon: 'fa-users' },
            { name: 'Cumulative Covid cases', value: 'covidCases', icon: 'fa-virus-covid' },
            { name: 'Cumulative Covid cases per 100,000', value: 'covidCasesPer100000', icon: 'fa-virus-covid' },
            { name: 'Median age', value: 'medianAge', icon: 'fa-child' },
            { name: 'IMD Decile', value: 'imdDecile', icon: 'fa-badge-percent' },
            { name: 'IMD Score', value: 'imdScore', icon: 'fa-money-bill-wave' },
        ];
    }

    get categories (): Array<{ colour?: string; text: string }> {
        return this.dataService!.regionNames.map((region) => ({
            colour: this.scatterChart?.color(region), text: region,
        }));
    }

    isReverseAxis (axis: string): boolean {
        return axis === 'populationDensity' || axis === 'medianAge' || axis === 'imdAverageScore';
    }

    get isMobile (): boolean {
        return window.innerWidth < 900;
    }

    axisTitle (value: string): string {
        switch (value) {
        case 'medianAge': return 'Median age';
        case 'covidCases': return 'Cumulative Covid cases';
        case 'covidCasesPer100000': return 'Cumulative Covid cases per 100,000';
        case 'imdDecile': return 'IMD Decile';
        case 'imdScore': return 'Deprivation score';
        case 'population': return 'Population';
        default:
            return this.dataService!.getDataTypeLabel(value as (CcgDataType | TrustDataType)) + (this.selectedSpeciality !== Speciality.Total ? ` (${this.selectedSpeciality})` : '');
        }
    }

    axisUnit (value: string): string {
        switch (value) {
        case 'medianAge': return 'Years';
        case 'population': return 'Total';
        default:
            return 'Total';
        }
    }

    getAxis (areaCode: string, axis: CcgDataType | keyof CcgMapping, speciality: Speciality): number {
        const data = this.getDataForAxis(axis, speciality);
        return data![areaCode];
    }

    getDataForAxis (axis: CcgDataType | keyof CcgMapping, speciality: Speciality): Record<string, number> {
        switch (axis) {
        case 'population': return this.dataService!.getCcgDetailGroupedByAreaCodes(axis as keyof CcgMapping) as Record<string, number>;
        case 'imdScore': return this.dataService!.getCcgDetailGroupedByAreaCodes(axis as keyof CcgMapping) as Record<string, number>;
        case 'imdDecile': return this.dataService!.getCcgDetailGroupedByAreaCodes(axis as keyof CcgMapping) as Record<string, number>;
        case 'medianAge': return this.dataService!.getCcgDetailGroupedByAreaCodes(axis as keyof CcgMapping) as Record<string, number>;
        case 'covidCasesPer100000': return this.dataService!.getCcgDetailGroupedByAreaCodes(axis as keyof CcgMapping) as Record<string, number>;
        case 'covidCases': return this.dataService!.getCcgDetailGroupedByAreaCodes(axis as keyof CcgMapping) as Record<string, number>;
        default: return this.dataService!.getCcgDataGroupedByAreaCode(axis as CcgDataType, this.latestDate, speciality);
        }
    }

    get latestDate (): string {
        if (!this.dataService || !this.dataService.ccgData?.Waiting) return '';
        return Object.keys(this.dataService.ccgData.Waiting!).sort((a, b) => (a < b ? 1 : -1))[0];
    }

    get sizeExents (): [number, number] {
        // if (this.dotSize === 'population') {
        //     const populationsByArea = Object.values(this.getDataForAxis('population') ?? {});
        //     return d3.extent(populationsByArea.filter((a) => a !== null) as Array<number>) as unknown as [number, number];
        // }
        return [0, 0];
    }

    getSize (areaCode: string): number {
        // if (this.dotSize === 'population') {
        //     const pop = this.getAxis(areaCode, 'population');
        //     return ((pop || 0) / this.sizeExents[1]) * 20;
        // }
        return 7;
    }

    get rSquaredValue (): number | undefined {
        const r = this.dataService?.leastSquares(
            this.chartData.map((a) => a.x),
            this.chartData.map((a) => a.y),
        ).r;
        return Math.round((r ?? 0) * 10000) / 10000;
    }

    get chartData (): Array<ScatterChartData> {
        const data: ScatterChartData[] = [];
        this.dataService!.areaCodes.forEach((areaCode) => {
            const xValue = this.getAxis(areaCode, this.xAxis, this.selectedSpeciality);
            const yValue = this.getAxis(areaCode, this.yAxis, this.selectedSpeciality);

            data.push({
                name: this.dataService!.getAreaNameFromAreaCode(areaCode, 'ccg'),
                x: xValue as number,
                y: yValue as number,
                category: this.dataService!.getRegionNameFromAreaCode(areaCode),
                size: this.getSize(areaCode),
            });
        });

        return data;
    }
}
