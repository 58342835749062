







































import NewsApi from '@/api/newsApi';
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { NewsItem } from '@/models/newsItem';

@Component
export default class Insight extends Vue {
    news: Array<NewsItem> = [];

    async mounted (): Promise<void> {
        this.news = (await (NewsApi.getNews())).ListItems;
    }

    formatDate (date: string): string {
        return dayjs(date).format('DD MMM YYYY');
    }
}
