/* eslint-disable @typescript-eslint/ban-ts-comment */
import Vue from 'vue';

export const generateGuid = (): string => {
    function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export default (vue: typeof Vue): void => {
    vue.directive('click-outside', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        bind (el: HTMLElement, binding: any) {
            if (el.id === undefined || el.id.length === 0) {
                el.id = generateGuid();
            }

            // Define Handler and cache it on the element
            const bubble = binding.modifiers.bubble;
            const handler = (e: MouseEvent) => {
                let clickedEl = e.target;
                if (bubble || (el.id !== (clickedEl as HTMLElement).id)) {
                    while (clickedEl) {
                        if ((clickedEl as HTMLElement).id === el.id) {
                            return;
                        }
                        clickedEl = (clickedEl as HTMLElement).parentElement;
                    }
                    binding.value(e);
                }
            };
            // @ts-ignore
            el.__vueClickOutside__ = handler;
            // add Event Listeners
            document.addEventListener('click', handler);
        },

        unbind (el) {
            // Remove Event Listeners
            // @ts-ignore
            document.removeEventListener('click', el.__vueClickOutside__);
            // @ts-ignore
            el.__vueClickOutside__ = null;
        },
    });
};
