// eslint-disable-next-line no-shadow
enum Speciality {
    Total = 'Total',
    Dermatology = 'Dermatology',
    Rheumatology = 'Rheumatology',
    Gastroenterology = 'Gastroenterology',
    Orthopaedics = 'Orthopaedics'
}

export default Speciality;
