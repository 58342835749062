
































import { DropdownItem } from '@/models/dropdownItem';
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { generateGuid } from '../directives/click-outside';

@Component({ name: 'dropdown-list-item' })
export default class Home extends Vue {
    @Prop() value!: string | number;

    @Prop() item!: DropdownItem;

    @Prop() hovered!: DropdownItem;

    @Prop() parent!: DropdownItem;

    @Prop() grandparent!: DropdownItem;

    @Prop() selected!: DropdownItem;

    id = '';

    dirty = false;

    selectedValue: DropdownItem | null = null;

    focused = false;

    created (): void {
        this.id = generateGuid();
    }

    get isHovered (): boolean {
        const checkChildren = (item: DropdownItem) => {
            if (item === this.hovered) return true;
            if (!item.children) return false;
            for (let i = 0; i < item.children.length; i += 1) {
                if (checkChildren(item.children[i])) return true;
            }
            return false;
        };
        return checkChildren(this.item);
    }

    get getDropdownStyle (): Record<string, string> {
        if (!this.hovered || !this.$refs.item) return {};
        const parentElement = (this.$refs.item as HTMLElement).parentElement?.parentElement as HTMLElement;
        let width: string | number = parentElement.getBoundingClientRect().width;
        let left = parentElement.getBoundingClientRect().left + parentElement.getBoundingClientRect().width;
        const top = parentElement.getBoundingClientRect().top;
        if (window.innerWidth < 1100) {
            left = parentElement.getBoundingClientRect().left;
        } else if (width + left > window.innerWidth && window.innerWidth >= 1100) {
            left = parentElement.getBoundingClientRect().left;
        } else {
            width = 'auto';
        }
        return {
            width: width === 'auto' ? width : `${width}px`,
            left: `${left + 2}px`,
            top: `${top}px`,
        };
    }

    findItem (items: Array<DropdownItem>, value: string | number): DropdownItem | null {
        if (!items) return null;
        for (let i = 0; i < items.length; i += 1) {
            if (items[i].value === value) return items[i];
            const item = this.findItem(items[i].children ?? [], value);
            if (item) return item;
        }
        return null;
    }

    goBack (): void {
        this.$emit('hovered-item', this.grandparent);
    }

    clickedOutside (): void {
        this.focused = false;
        this.$emit('hovered-item', null);
    }

    thisHovered (): void {
        if (window.innerWidth < 1100) return;
        this.$emit('hovered-item', this.item);
    }

    hoveredItem (item: DropdownItem): void {
        this.$emit('hovered-item', item);
    }

    scrollToItem (): void {
        if (this.selected === this.item) {
            const el = this.$refs.item as HTMLElement;
            if (el && el.parentElement) {
                const topPos = el.offsetTop;
                el.parentElement.scrollTop = topPos - el.offsetHeight;
            }
        }
    }

    enterPressed (): void {
        if (this.selected) {
            this.selectedValue = this.selected;
        }
        this.focused = false;
        (this.$refs.input as HTMLElement).blur();
    }

    tabPressed (): void {
        this.focused = false;
    }

    itemSelected (item: DropdownItem): void {
        this.$emit('item-selected', item);
    }

    caretMoved (): void {
        this.dirty = true;
        // this.showList();
    }

    @Watch('selectedValue')
    selectedValueChanged (): void {
        this.dirty = true;
        this.$emit('input', this.selectedValue ? this.selectedValue.value : null);
    }

    @Watch('value', { immediate: true })
    valueChanged (): void {
        const item = this.findItem(this.item?.children ?? [], this.value);
        this.selectedValue = item;
    }

    @Watch('selected')
    selectedChanged (): void {
        this.scrollToItem();
    }
}
