




























































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
      showMenu = false;

      showCookies = true;

      showContact = false;

      password = '';

      showPassword = true;

      error = false;

      created (): void {
          if (window.localStorage.getItem('cookie')) {
              this.showCookies = false;
          }
          if (process.env.NODE_ENV === 'development') {
              this.showPassword = false;
          }
      }

      dismissCookies (): void {
          window.localStorage.setItem('cookie', '1');
          this.showCookies = false;
      }

      get pageTitle (): string {
          return this.$route.name ?? 'Home';
      }

      submit (): void {
          if (this.password === 'janssenlcpwaitlist') {
              this.showPassword = false;
          } else {
              this.error = true;
          }
      }
}
