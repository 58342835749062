







































import { Component, Vue, Watch } from 'vue-property-decorator';
import MapComponent from '@/components/map.vue';
import { DropdownItem } from '@/models/dropdownItem';
import Dropdown from '@/components/dropdown.vue';
import Rates from '@/components/rates.vue';
import TrustDataType from '@/models/trustDataTypes';
import DataService from '@/services/dataService';
import SpecialitiesChart from '@/components/charts/specialities-chart.vue';
import HighchartsLineChart from '@/components/charts/highcharts-line-chart.vue';
import CcgDataType from '@/models/ccgDataTypes';
import Rankings from '@/components/rankings.vue';
import Speciality from '@/models/speciality';

@Component({
    components: {
        Dropdown,
        MapComponent,
        SpecialitiesChart,
        HighchartsLineChart,
        Rates,
        Rankings,
    },
})
export default class Home extends Vue {
    metric: TrustDataType | CcgDataType = CcgDataType.Waiting;

    selectedDate = '2021-11-01';

    dataService: DataService | null = null;

    showRankings = false;

    dropdownOptions: Array<DropdownItem> = [
        { name: 'Waiting list', value: CcgDataType.Waiting, icon: 'fa-calendar' },
        {
            name: 'Waiting list - hidden needs ', value: CcgDataType.Hidden, icon: 'fa-calendar', secondaryIcon: 'fa-eye-slash',
        },
        {
            name: 'Waiting list - unmet needs', value: CcgDataType.Unmet, icon: 'fa-calendar', secondaryIcon: 'fa-exclamation',
        },
        { name: 'Waiting list per 100,000', value: CcgDataType.WaitingPer100000, icon: 'fa-calendar-circle-user' },
        {
            name: 'Waiting list per 100,000 - hidden needs', value: CcgDataType.HiddenPer100000, icon: 'fa-calendar-circle-user', secondaryIcon: 'fa-eye-slash',
        },
        {
            name: 'Waiting list per 100,000 - unmet needs', value: CcgDataType.UnmetPer100000, icon: 'fa-calendar-circle-user', secondaryIcon: 'fa-exclamation',
        },
        { name: 'Cancer vs elective NHS targets', value: TrustDataType.CancerVsElectiveNHSTargets, icon: 'fa-disease' },
    ]

    specialityOptions: Array<DropdownItem> = [
        { name: 'All', value: Speciality.Total, icon: 'fa-star-of-life' },
        { name: 'Dermatology', value: Speciality.Dermatology, icon: 'fa-hand-dots' },
        { name: 'Rheumatology', value: Speciality.Rheumatology, icon: 'fa-skeleton' },
        { name: 'Gastroenterology', value: Speciality.Gastroenterology, icon: 'fa-stomach' },
        { name: 'Orthopaedics', value: Speciality.Orthopaedics, icon: 'fa-crutches' },
    ]

    selectedCcg: string | null = null;

    selectedTrust: string | null = '';

    selectedSpeciality = Speciality.Total;

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
        this.selectedDate = this.latestDate;
    }

    get loaded (): boolean {
        return this.dataService?.loaded ?? false;
    }

    get areaType (): 'ccg' | 'trust' | 'stp' {
        if (this.selectedCcg === 'stp' || this.dataService?.isStp(this.selectedCcg ?? '')) return 'stp';
        if (Object.values(CcgDataType).includes(this.metric as CcgDataType)) return 'ccg';
        return 'trust';
    }

    get selectedArea (): string | null {
        return this.areaType === 'trust' ? this.selectedTrust : this.selectedCcg;
    }

    get selectedAreaName (): string | undefined {
        switch (this.areaType) {
        case 'ccg':
            return this.dataService!.getAreaNameFromCcg(this.selectedCcg ?? '');
        case 'stp':
            return this.dataService!.getAreaNameFromStp(this.selectedCcg ?? '');
        case 'trust':
            return this.dataService!.getTrustNameFromTrustId(this.selectedTrust ?? '');
        default:
            return this.selectedCcg ?? '';
        }
    }

    get latestDate (): string {
        if (!this.dataService || !this.dataService.ccgData?.Waiting) return '';
        return Object.keys(this.dataService.ccgData.Waiting!).sort((a, b) => (a < b ? 1 : -1))[0];
    }

    get ccgList (): Array<DropdownItem> {
        return [
            { name: 'England', value: null, children: [] },
            {
                name: 'STP areas',
                value: 'stp',
                children: Object.values(this.dataService?.stpDetails ?? {}).map((stp) => ({
                    name: stp.stpName,
                    value: stp.stpCode,
                })).filter((value, index, self) => self.findIndex((a) => a.name === value.name) === index)
                    .sort((a, b) => (a.name > b.name ? 1 : -1)),
            },
            ...this.dataService?.regionCodes.sort((a, b) => (a > b ? 1 : -1)).map((a) => ({
                name: this.dataService!.getRegionNameFromRegionCode(a) ?? a,
                value: a,
                children: Object.values(this.dataService?.getAllCcgsForRegion(a) || []).map((b) => ({
                    name: b.ccgName,
                    value: b.ccgCode,

                })),
            })) || [],
        ];
    }

    get showingTrustData (): boolean {
        return this.metric === TrustDataType.CancerVsElectiveNHSTargets;
    }

    get trustList (): Array<DropdownItem> {
        return [
            { name: 'England', value: null, children: [] },
            ...this.dataService?.regionCodes.sort((a, b) => (a > b ? 1 : -1)).map((a) => ({
                name: this.dataService!.getRegionNameFromRegionCode(a) ?? a,
                value: a,
                children: Object.values(this.dataService?.getAllTrustsForRegion(a) || []).map((b) => ({
                    name: b.providerName,
                    value: b.providerCode,

                })),
            })) || []];
    }

    areaSelected (ccgCode: string | null): void {
        this.selectedCcg = ccgCode;
        if (this.areaType === 'trust') {
            this.selectedTrust = ccgCode;
        }
    }

    trustSelected (trustId: string | null): void {
        this.selectedTrust = trustId;
    }

    dateSelected (date: string): void {
        this.selectedDate = date;
    }

    specialitySelected (speciality: Speciality): void {
        this.selectedSpeciality = speciality;
    }

    @Watch('metric')
    metricChanged (oldItem: string, newItem: string): void {
        const previousType = (Object.values(CcgDataType).includes(oldItem as CcgDataType)) ? 'ccg' : 'trust';
        const newType = (Object.values(CcgDataType).includes(newItem as CcgDataType)) ? 'ccg' : 'trust';
        if (previousType !== newType) {
            (this.$refs.map as MapComponent).deselectArea();
            this.selectedTrust = null;
            this.selectedCcg = null;
            this.showRankings = false;
            this.$nextTick(() => {
                (this.$refs.map as MapComponent).resize();
                (this.$refs.lineChart as HighchartsLineChart).reflow();
            });
        }
    }

    selectedCcgChanged (): void {
        if (this.areaType === 'ccg') {
            this.$nextTick(() => {
                if (!this.selectedCcg) (this.$refs.map as MapComponent).deselectArea();
                else if (this.dataService!.isRegion(this.selectedCcg)) (this.$refs.map as MapComponent).selectRegionOnMap(this.selectedCcg);
                else (this.$refs.map as MapComponent).selectAreaOnMap(this.dataService!.getAreaCodeFromCcg(this.selectedCcg) ?? null);
            });
        } else if (this.selectedCcg === 'stp') {
            this.$nextTick(() => {
                (this.$refs.map as MapComponent).deselectArea();
            });
            this.selectedCcg = 'stp';
        } else {
            this.$nextTick(() => {
                (this.$refs.map as MapComponent).selectRegionOnMap(this.selectedCcg);
            });
        }
    }

    selectedTrustChanged (): void {
        if (!this.selectedTrust) (this.$refs.map as MapComponent).deselectArea();
        else if (this.dataService!.isRegion(this.selectedTrust)) (this.$refs.map as MapComponent).selectRegionOnMap(this.selectedTrust);
        else {
            const marker = this.dataService?.trustDetailsByTrust[this.selectedTrust];
            if (!marker) {
                (this.$refs.map as MapComponent).deselectArea();
                return;
            }
            const areaCode = (this.$refs.map as MapComponent).getAreaByLatLong(marker.latitude, marker.longitude);
            this.selectedCcg = this.dataService!.getCcgCodeFromAreaCode(areaCode);
            (this.$refs.map as MapComponent).selectAreaOnMap(areaCode ?? null);
        }
    }
}
