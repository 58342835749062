/* eslint-disable no-restricted-properties */
import { CcgMapping } from '@/models/ccgMapping';
import TrustDataType from '@/models/trustDataTypes';
import { TrustData } from '@/models/trustData';
import { TrustMapping } from '@/models/trustMapping';
import * as d3 from 'd3';
import Vue from 'vue';
import CcgDataType from '@/models/ccgDataTypes';
import { ExtentsFunction } from '@lcp/map-chart';
import { Ranking } from '@/models/ranking';
import Speciality from '@/models/speciality';
import { StpMapping } from '@/models/stpMapping';

export default class DataService {
    ccgDetails: { [areaCode: string]: CcgMapping } = {};

    trustDetailsByCcg: { [ccgCode: string]: TrustMapping } = {}

    trustDetailsByTrust: { [trustCode: string]: TrustMapping } = {}

    trustData: { [key in TrustDataType]?: { [date: string]: { [ speciality in Speciality]: { [trustCode: string ]: number}}}} = {}

    ccgData: { [key in CcgDataType]?: { [date: string]: { [speciality in Speciality]: { [ccgCode: string ]: number}}}} = {}

    stpData: { [key in CcgDataType]?: { [date: string]: { [speciality in Speciality]: { [ccgCode: string ]: number}}}} = {}

    stpDetails: { [ccgCode: string]: StpMapping } = {}

    projections: { [key in CcgDataType]?: { [date: string]: { [speciality in Speciality ]: {[ scenario: string]: number}}}} = {};

    loaded = false;

    colourRange =['#DA291C', '#FAE100', '#78BE20'];

    private static dataService?: DataService;

    get areaCodes (): Array<string> {
        return Object.keys(this.ccgDetails);
    }

    get ccgCodes (): Array<string> {
        return [...new Set(Object.values(this.ccgDetails).map((a) => a.ccgCode))];
    }

    get stpCodes (): Array<string> {
        return [...new Set(Object.values(this.stpDetails).map((a) => a.stpCode))];
    }

    get trusts (): Array<string> {
        return Object.keys(this.trustDetailsByTrust);
    }

    get regionNames (): Array<string> {
        return [...new Set(Object.values(this.ccgDetails).map((a) => a.regionName))];
    }

    get regionCodes (): Array<string> {
        return [...new Set(Object.values(this.ccgDetails).map((a) => a.regionCode))];
    }

    get dates (): Array<string> {
        return Object.keys(this.trustData.PercentWithin14Days!);
    }

    get projectionDates (): Array<string> {
        return Object.keys(this.projections[CcgDataType.Waiting]!);
    }

    static async get (): Promise<DataService> {
        if (!this.dataService) {
            this.dataService = new DataService();
            await this.dataService.initialise();
        }
        return this.dataService;
    }

    async initialise (): Promise<void> {
        this.loaded = false;
        await this.getCcgMappings();
        await this.getTrustMappings();
        await this.getStpMappings();

        await this.getTrustData('cancer/TrustPercentWithin14DaysTotal', TrustDataType.PercentWithin14Days, Speciality.Total);
        await this.getTrustData('cancer/RegionalPercentWithin14Days', TrustDataType.PercentWithin14Days, Speciality.Total);
        await this.getTrustData('cancer/NationalPercentWithin14Days', TrustDataType.PercentWithin14Days, Speciality.Total);

        // await this.getTrustData('Total percent within 18 weeks', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Total);

        await this.getTrustData('admitted/NationalAdmittedPercentWithin18WeeksDermo', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Dermatology);
        await this.getTrustData('admitted/NationalAdmittedPercentWithin18WeeksGastro', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('admitted/NationalAdmittedPercentWithin18WeeksOrtho', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Orthopaedics);
        await this.getTrustData('admitted/NationalAdmittedPercentWithin18WeeksRheum', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Rheumatology);
        await this.getTrustData('admitted/NationalAdmittedPercentWithin18WeeksTotal', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Total);
        await this.getTrustData('admitted/RegionalAdmittedPercentWithin18WeeksDermo', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('admitted/RegionalAdmittedPercentWithin18WeeksGastro', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('admitted/RegionalAdmittedPercentWithin18WeeksOrtho', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Orthopaedics);
        await this.getTrustData('admitted/RegionalAdmittedPercentWithin18WeeksRheum', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Rheumatology);
        await this.getTrustData('admitted/TrustAdmittedPercentSeenWithin18WeeksTotal', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Total);
        await this.getTrustData('admitted/TrustAdmittedPercentSeenWithin18WeeksDerm', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('admitted/TrustAdmittedPercentSeenWithin18WeeksGastro', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('admitted/TrustAdmittedPercentSeenWithin18WeeksOrtho', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Orthopaedics);
        await this.getTrustData('admitted/TrustAdmittedPercentSeenWithin18WeeksRheum', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Rheumatology);
        await this.getTrustData('admitted/TrustAdmittedPercentSeenWithin18WeeksTotal', TrustDataType.PercentWithin18WeeksAdmitted, Speciality.Total);

        await this.getTrustData('non-admitted/NationalNonAdmittedPercentWithin18WeeksDerm', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Dermatology);
        await this.getTrustData('non-admitted/NationalNonAdmittedPercentWithin18WeeksGastro', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('non-admitted/NationalNonAdmittedPercentWithin18WeeksOrtho', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Orthopaedics);
        await this.getTrustData('non-admitted/NationalNonAdmittedPercentWithin18WeeksRheum', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Rheumatology);
        await this.getTrustData('non-admitted/NationalNonAdmittedPercentWithin18WeeksTotal', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Total);
        await this.getTrustData('non-admitted/RegionalNonAdmittedPercentSeenIn18WeeksDerm', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Dermatology);
        await this.getTrustData('non-admitted/RegionalNonAdmittedPercentSeenIn18WeeksGastro', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('non-admitted/RegionalNonAdmittedPercentSeenIn18WeeksOrtho', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Orthopaedics);
        await this.getTrustData('non-admitted/RegionalNonAdmittedPercentSeenIn18WeeksRheum', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Rheumatology);
        await this.getTrustData('non-admitted/RegionalNonAdmittedPercentSeenIn18WeeksTotal', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Total);
        await this.getTrustData('non-admitted/TrustNonAdmittedPercentSeenWithin18WeeksDermo', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Dermatology);
        await this.getTrustData('non-admitted/TrustNonAdmittedPercentSeenWithin18WeeksGastro', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Gastroenterology);
        await this.getTrustData('non-admitted/TrustNonAdmittedPercentSeenWithin18WeeksOrtho', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Orthopaedics);
        await this.getTrustData('non-admitted/TrustNonAdmittedPercentSeenWithin18WeeksRheum', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Rheumatology);
        await this.getTrustData('non-admitted/TrustNonAdmittedPercentSeenWithin18WeeksTotal', TrustDataType.PercentWithin18WeeksNonAdmitted, Speciality.Total);

        await this.getCcgData('ccg-waiting-times/TotalIncompletePathwaysTotal', CcgDataType.Waiting, Speciality.Total);
        await this.getCcgData('ccg-waiting-times/TotalIncompletePathwaysGastro', CcgDataType.Waiting, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times/TotalIncompletePathwaysOrtho', CcgDataType.Waiting, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times/TotalIncompletePathwaysDermo', CcgDataType.Waiting, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times/TotalIncompletePathwaysRheumy', CcgDataType.Waiting, Speciality.Rheumatology);
        await this.getCcgData('ccg-waiting-times/RegionalTotalIncompletePathwaysTotal', CcgDataType.Waiting, Speciality.Total);
        await this.getCcgData('ccg-waiting-times/RegionalTotalIncompletePathwaysGastro', CcgDataType.Waiting, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times/RegionalTotalIncompletePathwaysOrtho', CcgDataType.Waiting, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times/RegionalTotalIncompletePathwaysDermo', CcgDataType.Waiting, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times/RegionalTotalIncompletePathwaysRheumy', CcgDataType.Waiting, Speciality.Rheumatology);

        await this.getCcgData('ccg-waiting-times/hidden/HiddenNeedTotal', CcgDataType.Hidden, Speciality.Total);
        await this.getCcgData('ccg-waiting-times/hidden/HiddenNeedGastro', CcgDataType.Hidden, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times/hidden/HiddenNeedOrtho', CcgDataType.Hidden, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times/hidden/HiddenNeedDermo', CcgDataType.Hidden, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times/hidden/HiddenNeedRheumy', CcgDataType.Hidden, Speciality.Rheumatology);
        await this.getCcgData('ccg-waiting-times/hidden/NationalHiddenNeedTotal', CcgDataType.Hidden, Speciality.Total);
        await this.getCcgData('ccg-waiting-times/hidden/NationalHiddenNeedGastro', CcgDataType.Hidden, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times/hidden/NationalHiddenNeedOrtho', CcgDataType.Hidden, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times/hidden/NationalHiddenNeedDermo', CcgDataType.Hidden, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times/hidden/NationalHiddenNeedRheumy', CcgDataType.Hidden, Speciality.Rheumatology);
        await this.getCcgData('ccg-waiting-times/hidden/RegionalHiddenNeedTotal', CcgDataType.Hidden, Speciality.Total);
        await this.getCcgData('ccg-waiting-times/hidden/RegionalHiddenNeedGastro', CcgDataType.Hidden, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times/hidden/RegionalHiddenNeedOrtho', CcgDataType.Hidden, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times/hidden/RegionalHiddenNeedDermo', CcgDataType.Hidden, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times/hidden/RegionalHiddenNeedRheumy', CcgDataType.Hidden, Speciality.Rheumatology);

        await this.getCcgData('ccg-waiting-times-per-100000/TotalIncompletePathwaysper100000Total', CcgDataType.WaitingPer100000, Speciality.Total);
        await this.getCcgData('ccg-waiting-times-per-100000/TotalIncompletePathwaysper100000Gastro', CcgDataType.WaitingPer100000, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times-per-100000/TotalIncompletePathwaysper100000Ortho', CcgDataType.WaitingPer100000, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times-per-100000/TotalIncompletePathwaysper100000Dermo', CcgDataType.WaitingPer100000, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times-per-100000/TotalIncompletePathwaysper100000Rheumy', CcgDataType.WaitingPer100000, Speciality.Rheumatology);
        await this.getCcgData('ccg-waiting-times-per-100000/RegionalTotalIncompletePathwaysper100000Total', CcgDataType.WaitingPer100000, Speciality.Total);
        await this.getCcgData('ccg-waiting-times-per-100000/RegionalTotalIncompletePathwaysper100000Gastro', CcgDataType.WaitingPer100000, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times-per-100000/RegionalTotalIncompletePathwaysper100000Ortho', CcgDataType.WaitingPer100000, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times-per-100000/RegionalTotalIncompletePathwaysper100000Dermo', CcgDataType.WaitingPer100000, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times-per-100000/RegionalTotalIncompletePathwaysper100000Rheumy', CcgDataType.WaitingPer100000, Speciality.Rheumatology);

        await this.getCcgData('ccg-waiting-times-per-100000/hidden/HiddenNeedper100000Total', CcgDataType.HiddenPer100000, Speciality.Total);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/HiddenNeedper100000Gastro', CcgDataType.HiddenPer100000, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/HiddenNeedper100000Ortho', CcgDataType.HiddenPer100000, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/HiddenNeedper100000Dermo', CcgDataType.HiddenPer100000, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/HiddenNeedper100000Rheumy', CcgDataType.HiddenPer100000, Speciality.Rheumatology);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/RegionalHiddenNeedper100000Total', CcgDataType.HiddenPer100000, Speciality.Total);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/RegionalHiddenNeedper100000Gastro', CcgDataType.HiddenPer100000, Speciality.Gastroenterology);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/RegionalHiddenNeedper100000Ortho', CcgDataType.HiddenPer100000, Speciality.Orthopaedics);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/RegionalHiddenNeedper100000Dermo', CcgDataType.HiddenPer100000, Speciality.Dermatology);
        await this.getCcgData('ccg-waiting-times-per-100000/hidden/RegionalHiddenNeedper100000Rheumy', CcgDataType.HiddenPer100000, Speciality.Rheumatology);

        await this.getStpData('stp/STPTotalIncompletePathways_Total', CcgDataType.Waiting, Speciality.Total);
        await this.getStpData('stp/STPTotalIncompletePathways_Rheumy', CcgDataType.Waiting, Speciality.Rheumatology);
        await this.getStpData('stp/STPTotalIncompletePathways_Ortho', CcgDataType.Waiting, Speciality.Orthopaedics);
        await this.getStpData('stp/STPTotalIncompletePathways_Gastro', CcgDataType.Waiting, Speciality.Gastroenterology);
        await this.getStpData('stp/STPTotalIncompletePathways_Dermo', CcgDataType.Waiting, Speciality.Dermatology);
        await this.getStpData('stp/STPHiddenNeed_Total', CcgDataType.Hidden, Speciality.Total);
        await this.getStpData('stp/STPHiddenNeed_Rheumy', CcgDataType.Hidden, Speciality.Rheumatology);
        await this.getStpData('stp/STPHiddenNeed_Ortho', CcgDataType.Hidden, Speciality.Orthopaedics);
        await this.getStpData('stp/STPHiddenNeed_Gastro', CcgDataType.Hidden, Speciality.Gastroenterology);
        await this.getStpData('stp/STPHiddenNeed_Dermo', CcgDataType.Hidden, Speciality.Dermatology);

        await this.getStpData('stp/STPTotalIncompletePathwaysper100000_Total', CcgDataType.WaitingPer100000, Speciality.Total);
        await this.getStpData('stp/STPTotalIncompletePathwaysper100000_Rheumy', CcgDataType.WaitingPer100000, Speciality.Rheumatology);
        await this.getStpData('stp/STPTotalIncompletePathwaysper100000_Ortho', CcgDataType.WaitingPer100000, Speciality.Orthopaedics);
        await this.getStpData('stp/STPTotalIncompletePathwaysper100000_Gastro', CcgDataType.WaitingPer100000, Speciality.Gastroenterology);
        await this.getStpData('stp/STPTotalIncompletePathwaysper100000_Dermo', CcgDataType.WaitingPer100000, Speciality.Dermatology);
        await this.getStpData('stp/STPHiddenNeedPer100000_Total', CcgDataType.HiddenPer100000, Speciality.Total);
        await this.getStpData('stp/STPHiddenNeedPer100000_Rheumy', CcgDataType.HiddenPer100000, Speciality.Rheumatology);
        await this.getStpData('stp/STPHiddenNeedPer100000_Ortho', CcgDataType.HiddenPer100000, Speciality.Orthopaedics);
        await this.getStpData('stp/STPHiddenNeedPer100000_Gastro', CcgDataType.HiddenPer100000, Speciality.Gastroenterology);
        await this.getStpData('stp/STPHiddenNeedPer100000_Dermo', CcgDataType.HiddenPer100000, Speciality.Dermatology);

        await this.getProjections('projections/All specialities', Speciality.Total);
        await this.getProjections('projections/Dermatology', Speciality.Dermatology);
        await this.getProjections('projections/Gastroenterology', Speciality.Gastroenterology);
        await this.getProjections('projections/Orthopaedics', Speciality.Orthopaedics);
        await this.getProjections('projections/Rheumatology', Speciality.Rheumatology);
        this.calculateUnmetNeeds(CcgDataType.Waiting, CcgDataType.Hidden, CcgDataType.Unmet);
        this.calculateUnmetNeeds(CcgDataType.WaitingPer100000, CcgDataType.HiddenPer100000, CcgDataType.UnmetPer100000);
        this.loaded = true;
    }

    async getTrustMappings (): Promise<void> {
        await d3.csv('/ProviderCodestoKeep.csv').then((response) => {
            const data = response as unknown as Array<TrustMapping>;
            data.forEach((row) => {
                Vue.set(this.trustDetailsByCcg, row.ccgCode, row);
                Vue.set(this.trustDetailsByTrust, row.providerCode, row);
            });
        });
    }

    async getCcgMappings (): Promise<void> {
        await d3.csv('/CCG_Mappings.csv').then((response) => {
            const data = response as unknown as Array<CcgMapping>;
            data.forEach((row) => {
                Vue.set(this.ccgDetails, row.areaCode, row);
            });
        });
    }

    async getStpMappings (): Promise<void> {
        await d3.csv('/stp/STP to CCG Codes.csv').then((response) => {
            const data = response as unknown as Array<StpMapping>;
            data.forEach((row) => {
                Vue.set(this.stpDetails, row.ccgCode, row);
            });
        });
    }

    calculateUnmetNeeds (waitingType: CcgDataType, hiddenType: CcgDataType, unmetType: CcgDataType): void {
        if (!this.ccgData[unmetType]) Vue.set(this.ccgData, unmetType, {});
        Object.keys(this.ccgData[waitingType]!).forEach((date) => {
            if (!this.ccgData[unmetType]![date]) {
                Vue.set(this.ccgData[unmetType]!, date, {});
            }
            Object.keys(this.ccgData[waitingType]![date]).forEach((speciality) => {
                if (!this.ccgData[unmetType]![date][speciality as Speciality]) {
                    Vue.set(this.ccgData[unmetType]![date], speciality, {});
                }
                Object.keys(this.ccgData[waitingType]![date][speciality as Speciality]).forEach((ccg) => {
                    Vue.set(this.ccgData[unmetType]![date][speciality as Speciality], ccg, this.ccgData[waitingType]![date][speciality as Speciality][ccg] + (this.ccgData[hiddenType]![date]?.[speciality as Speciality]?.[ccg] ?? 0));
                });
            });
        });

        if (!this.stpData[unmetType]) Vue.set(this.stpData, unmetType, {});
        Object.keys(this.stpData[waitingType]!).forEach((date) => {
            if (!this.stpData[unmetType]![date]) {
                Vue.set(this.stpData[unmetType]!, date, {});
            }
            Object.keys(this.stpData[waitingType]![date]).forEach((speciality) => {
                if (!this.stpData[unmetType]![date][speciality as Speciality]) {
                    Vue.set(this.stpData[unmetType]![date], speciality, {});
                }
                Object.keys(this.stpData[waitingType]![date][speciality as Speciality]).forEach((stp) => {
                    Vue.set(this.stpData[unmetType]![date][speciality as Speciality], stp, this.stpData[waitingType]![date][speciality as Speciality][stp] + (this.stpData[hiddenType]![date]?.[speciality as Speciality]?.[stp] ?? 0));
                });
            });
        });
    }

    async getTrustData (csvName: string, dataName: TrustDataType, speciality: Speciality): Promise<void> {
        await d3.csv(`/${csvName}.csv`).then((response) => {
            const data = response as unknown as Array<TrustData>;
            if (!this.trustData[dataName]) {
                Vue.set(this.trustData, dataName, {});
            }
            data.forEach((row) => {
                Object.keys(row).forEach((column) => {
                    if (column === 'MonthPeriod') return;
                    if (!this.trustData[dataName]![row.MonthPeriod]) {
                        Vue.set(this.trustData[dataName]!, row.MonthPeriod, {});
                    }
                    if (!this.trustData[dataName]![row.MonthPeriod][speciality]) {
                        Vue.set(this.trustData[dataName]![row.MonthPeriod], speciality, {});
                    }
                    Vue.set(this.trustData[dataName]![row.MonthPeriod][speciality], column, Number(row[column]));
                });
            });
        });
    }

    async getCcgData (csvName: string, dataName: CcgDataType, speciality: Speciality): Promise<void> {
        await d3.csv(`/${csvName}.csv`).then((response) => {
            const data = response as unknown as Array<TrustData>;
            if (!this.ccgData[dataName]) Vue.set(this.ccgData, dataName, {});
            data.forEach((row) => {
                Object.keys(row).forEach((column) => {
                    if (column === 'MonthPeriod') return;
                    if (!this.ccgData[dataName]![row.MonthPeriod]) {
                        Vue.set(this.ccgData[dataName]!, row.MonthPeriod, {});
                    }
                    if (!this.ccgData[dataName]![row.MonthPeriod][speciality]) {
                        Vue.set(this.ccgData[dataName]![row.MonthPeriod], speciality, {});
                    }
                    Vue.set(this.ccgData[dataName]![row.MonthPeriod][speciality], column, Number(row[column]));
                });
            });
        });
    }

    async getStpData (csvName: string, dataName: CcgDataType, speciality: Speciality): Promise<void> {
        await d3.csv(`/${csvName}.csv`).then((response) => {
            const data = response as unknown as Array<TrustData>;
            if (!this.stpData[dataName]) Vue.set(this.stpData, dataName, {});
            data.forEach((row) => {
                Object.keys(row).forEach((column) => {
                    if (column === 'MonthPeriod') return;
                    if (!this.stpData[dataName]![row.MonthPeriod]) {
                        Vue.set(this.stpData[dataName]!, row.MonthPeriod, {});
                    }
                    if (!this.stpData[dataName]![row.MonthPeriod][speciality]) {
                        Vue.set(this.stpData[dataName]![row.MonthPeriod], speciality, {});
                    }
                    Vue.set(this.stpData[dataName]![row.MonthPeriod][speciality], column, Number(row[column]));
                });
            });
        });
    }

    async getProjections (csvName: string, speciality: Speciality): Promise<void> {
        await d3.csv(`/${csvName}.csv`).then((response) => {
            response.forEach((row) => {
                const date = row.Date as string;
                Object.keys(row).forEach((key) => {
                    if (key === 'Date') return;
                    const scenario = key.split('-')[0].trim();
                    const type = key.split('-')[1].trim();
                    let dataType = CcgDataType.Waiting;

                    switch (type) {
                    case 'total unmet': dataType = CcgDataType.Unmet; break;
                    case 'hidden': dataType = CcgDataType.Hidden; break;
                    default: dataType = CcgDataType.Waiting; break;
                    }

                    if (!this.projections[dataType]) Vue.set(this.projections, dataType, {});
                    if (!this.projections[dataType]![date]) Vue.set(this.projections[dataType]!, date, {});
                    if (!this.projections[dataType]![date][speciality]) Vue.set(this.projections[dataType]![date], speciality, {});
                    const val = row[key]?.replace(',', '').replace(',', '');
                    Vue.set(this.projections[dataType]![date][speciality], scenario, Number(val));
                });
            });
        });
    }

    isHigherBetter (metric: TrustDataType | CcgDataType): boolean {
        switch (metric) {
        case TrustDataType.PercentWithin18WeeksAdmitted: return true;
        case TrustDataType.PercentWithin18WeeksNonAdmitted: return true;
        case TrustDataType.PercentWithin14Days: return true;
        default: return false;
        }
    }

    isRegion (name: string): boolean {
        return this.regionCodes.includes(name);
    }

    isStp (stpOrCcgCode: string): boolean {
        return !!Object.values(this.stpDetails).find((a) => a.stpCode === stpOrCcgCode);
    }

    getCcgForTrust (trustId: string): string {
        return this.trustDetailsByTrust[trustId].ccgCode;
    }

    getRegionNameFromAreaCode (areaCode: string): string {
        return this.ccgDetails[areaCode]?.regionName ?? 'unknown';
    }

    getRegionCodeFromAreaCode (areaCode: string, areaType: 'ccg' | 'trust' | 'stp'): string {
        if (areaType === 'stp') {
            return this.getStpCodeFromAreaCode(areaCode);
        }
        return this.ccgDetails[areaCode]?.regionCode ?? 'unknown';
    }

    getAllCcgsForRegion (regionCode: string): Array<CcgMapping> {
        return Object.values(this.ccgDetails).filter((a) => a.regionCode === regionCode);
    }

    getAllTrustsForRegion (regionCode: string): Array<TrustMapping> {
        return Object.values(this.trustDetailsByTrust).filter((a) => a.regionCode === regionCode);
    }

    getDataGroupedByTrust (metric: TrustDataType, date: string, speciality: Speciality): Record<string, number> {
        return this.trustData[metric]?.[date]?.[speciality] ?? {};
    }

    getDataGroupedByCcg (metric: CcgDataType, date: string, speciality: Speciality): Record<string, number> {
        return this.ccgData[metric]![date]?.[speciality];
    }

    getDataGroupedByStp (metric: CcgDataType, date: string, speciality: Speciality): Record<string, number> {
        return this.stpData[metric]![date]?.[speciality];
    }

    getRankedCcgData (metric: CcgDataType, date: string, speciality: Speciality): Array<Ranking> {
        const data = this.getDataGroupedByCcg(metric, date, speciality);
        return Object.keys(data)
            .filter((a) => this.ccgCodes.includes(a))
            .sort((a, b) => data[a] - data[b])
            .map((code, i) => ({
                code,
                name: this.getAreaNameFromCcg(code),
                ranking: i,
                value: data[code],
            }));
    }

    getRankedStpData (metric: CcgDataType, date: string, speciality: Speciality): Array<Ranking> {
        const data = this.getDataGroupedByStp(metric, date, speciality);
        return Object.keys(data)
            .filter((a) => this.stpCodes.includes(a))
            .sort((a, b) => data[a] - data[b])
            .map((code, i) => ({
                code,
                name: this.getAreaNameFromStp(code),
                ranking: i,
                value: data[code],
            }));
    }

    getRankedTrustData (metric: TrustDataType, date: string, speciality: Speciality): Array<Ranking> {
        const data = this.getDataGroupedByTrust(metric, date, speciality);
        return Object.keys(data)
            .filter((a) => this.trusts.includes(a))
            .sort((a, b) => data[a] - data[b])
            .map((code, i) => ({
                code,
                name: this.getTrustNameFromTrustId(code),
                ranking: i,
                value: data[code],
            }));
    }

    getDataTypeLabel (dataType: TrustDataType | CcgDataType): string {
        switch (dataType) {
        case TrustDataType.PercentWithin14Days: return 'Cancer referral % seen within 14 days';
        case TrustDataType.PercentWithin18WeeksAdmitted: return 'Admitted patients % seen within 18 weeks';
        case TrustDataType.PercentWithin18WeeksNonAdmitted: return 'Non-admitted patients % seen within 18 weeks';
        case TrustDataType.CancerVsElectiveNHSTargets: return 'Cancer vs elective NHS targets';
        case CcgDataType.Waiting: return 'Waiting list';
        case CcgDataType.Unmet: return 'Waiting list - Unmet needs';
        case CcgDataType.Hidden: return 'Waiting list - Hidden needs';
        case CcgDataType.WaitingPer100000: return 'Waiting list per 100,000';
        case CcgDataType.UnmetPer100000: return 'Waiting list per 100,000 - Unmet needs';
        case CcgDataType.HiddenPer100000: return 'Waiting list per 100,000 - Hidden needs';
        default: return '';
        }
    }

    getAreaTotalForDate (areaType: 'ccg' | 'trust' | 'stp', ccgOrTrustCode: string, date: string, dataType: TrustDataType | CcgDataType, speciality: Speciality): number {
        if (!ccgOrTrustCode || ccgOrTrustCode === 'stp') return this.getNationalTotalForDate(areaType, date, dataType, speciality);
        if (areaType === 'ccg') {
            return this.ccgData[dataType as CcgDataType]![date]?.[speciality]?.[ccgOrTrustCode] ?? 0;
        }
        if (areaType === 'stp') {
            return this.stpData[dataType as CcgDataType]![date]?.[speciality]?.[ccgOrTrustCode] ?? 0;
        }
        return this.trustData[dataType as TrustDataType]![date]?.[speciality]?.[ccgOrTrustCode] ?? 0;
    }

    getNationalTotalForDate (areaType: 'ccg' | 'trust' | 'stp', date: string, dataType: TrustDataType | CcgDataType, speciality: Speciality): number {
        if (areaType === 'ccg' || areaType === 'stp') {
            return this.ccgData[dataType as CcgDataType]?.[date]?.[speciality]?.Total ?? 0;
        }

        return this.trustData[dataType as TrustDataType]![date]?.[speciality]?.Percent;
    }

    getProjectionsForDate (date: string, dataType: CcgDataType, scenario: string, speciality: Speciality): number {
        return this.projections[dataType]?.[date][speciality][scenario] ?? 0;
    }

    getCcgDataGroupedByAreaCode (metric: CcgDataType, date: string, speciality: Speciality): Record<string, number> {
        const data: Record<string, number> = {};
        this.areaCodes.forEach((areaCode) => {
            data[areaCode] = this.ccgData[metric]![date]?.[speciality][this.getCcgCodeFromAreaCode(areaCode)] ?? 0;
        });
        return data;
    }

    getStpDataGroupedByAreaCode (metric: CcgDataType, date: string, speciality: Speciality): Record<string, number> {
        const data: Record<string, number> = {};
        this.areaCodes.forEach((areaCode) => {
            const stpCode = this.getStpCodeFromAreaCode(areaCode);
            data[areaCode] = this.stpData[metric]?.[date]?.[speciality][stpCode] ?? 0;
        });
        return data;
    }

    leastSquares (xData: Array<number>, yData: Array<number>): { r?: number; b?: number; m?: number} {
        let sumX = 0;
        let sumY = 0;
        let sumXY = 0;
        let sumXX = 0;
        let count = 0;

        /*
         * We'll use those variables for faster read/write access.
         */
        let x = 0;
        let y = 0;
        const valuesLength = xData.length;

        if (valuesLength !== yData.length) {
            throw new Error('The parameters values_x and values_y need to have same size!');
        }

        /*
         * Nothing to do.
         */
        if (valuesLength === 0) {
            return {};
        }

        /*
         * Calculate the sum for each of the parts necessary.
         */
        for (let v = 0; v < valuesLength; v += 1) {
            x = xData[v];
            y = yData[v];
            sumX += x;
            sumY += y;
            sumXX += x * x;
            sumXY += x * y;
            count += 1;
        }

        /*
         * Calculate m and b for the formular:
         * y = x * m + b
         */
        const m = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
        const b = (sumY / count) - (m * sumX) / count;

        // calculate r squared
        // Sum of the squared distances between the actual Y values and their mean
        const meanY = sumY / count;
        let yMeanSquared = 0;
        yData.forEach((d) => {
            const yDiff = d - meanY;
            yMeanSquared += Math.pow(yDiff, 2);
        });
        // console.log(y_meansquared);

        // sum of squared distances between the actual and the predicted Y values.
        // predicted y minus actual y
        // y = mx + b
        let yPredictedError = 0;
        for (let i = 0; i < valuesLength; i += 1) {
            const xreal = xData[i];
            const ytest = (m * xreal) + b;
            const yreal = yData[i];
            const yerr = ytest - yreal;
            yPredictedError += Math.pow(yerr, 2);
        }
        // console.log(y_predicted_error);

        // calulate r squared from y_predicted_error and y_meansquared
        const r = 1 - (yPredictedError / yMeanSquared);

        return { b, m, r };
    }

    getMinMaxForStp (metric: CcgDataType, speciality: Speciality): [number, number, number] {
        let min: number | null = null;
        let max: number | null = null;
        this.dates.forEach((date) => {
            this.stpCodes.forEach((stpCode) => {
                const val = this.stpData[metric]?.[date]?.[speciality][stpCode];
                if (!val) return;
                if (min === null || val < min) min = val;
                if (max === null || val > max) max = val;
            });
        });
        if (this.isHigherBetter(metric)) {
            return [max!, min! + (max! - min!) / 2, min!];
        }
        return [min!, min! + (max! - min!) / 2, max!];
    }

    getMinMaxForRegionDataType (metric: CcgDataType, speciality: Speciality): [number, number, number] {
        let min: number | null = null;
        let max: number | null = null;
        this.dates.forEach((date) => {
            this.regionCodes.forEach((ccg) => {
                // if (!this.ccgData[metric]![date]) return;
                const val = this.ccgData[metric]![date]?.[speciality][ccg];
                if (!val) return;
                if (min === null || val < min) min = val;
                if (max === null || val > max) max = val;
            });
        });
        if (this.isHigherBetter(metric)) {
            return [max!, min! + (max! - min!) / 2, min!];
        }
        return [min!, min! + (max! - min!) / 2, max!];
    }

    getMinMaxForCcgDataType (metric: CcgDataType, speciality: Speciality): [number, number, number] {
        let min: number | null = null;
        let max: number | null = null;
        this.dates.forEach((date) => {
            this.ccgCodes.forEach((ccg) => {
                // if (!this.ccgData[metric]![date]) return;
                const val = this.ccgData[metric]![date]?.[speciality][ccg];
                if (!val) return;
                if (min === null || val < min) min = val;
                if (max === null || val > max) max = val;
            });
        });
        if (this.isHigherBetter(metric)) {
            return [max!, min! + (max! - min!) / 2, min!];
        }
        return [min!, min! + (max! - min!) / 2, max!];
    }

    getMinMaxForTrustDataType (metric: TrustDataType, speciality: Speciality): [number, number, number] {
        let min: number | null = null;
        let max: number | null = null;
        this.dates.forEach((date) => {
            this.trusts.forEach((trust) => {
                const val = this.trustData[metric]![date]?.[speciality][trust];
                if (min === null || val < min) min = val;
                if (max === null || val > max) max = val;
            });
        });
        if (this.isHigherBetter(metric)) {
            return [max!, min! + (max! - min!) / 2, min!];
        }
        return [min!, min! + (max! - min!) / 2, max!];
    }

    getAreaCodeFromCcg (ccgCode: string): string | undefined {
        return Object.values(this.ccgDetails).find((a) => a.ccgCode === ccgCode)?.areaCode;
    }

    getCcgCodesForStp (stpCode: string): Array<string> {
        return Object.values(this.stpDetails).filter((a) => a.stpCode === stpCode).map((a) => a.ccgCode);
    }

    getAreaCodesFromStp (stpCode: string): Array<string> {
        const ccgCodes = this.getCcgCodesForStp(stpCode);
        return Object.values(this.ccgDetails).filter((a) => ccgCodes.includes(a.ccgCode)).map((a) => a.areaCode);
    }

    getStpCodeFromAreaCode (areaCode: string): string {
        const ccgCode = this.getCcgCodeFromAreaCode(areaCode);
        const stpCode = Object.values(this.stpDetails).find((a) => a.ccgCode === ccgCode)?.stpCode;
        if (!stpCode) {
            console.log(areaCode, ccgCode);
            return areaCode;
        }
        return stpCode;
    }

    getAreaNameFromCcg (ccgCode: string): string | undefined {
        return Object.values(this.ccgDetails).find((a) => a.ccgCode === ccgCode)?.ccgName;
    }

    getAreaNameFromStp (stpCode: string): string | undefined {
        if (stpCode === 'stp') return 'STP areas';
        return Object.values(this.stpDetails).find((a) => a.stpCode === stpCode)?.stpName;
    }

    getRegionNameFromRegionCode (regionCode: string): string | undefined {
        return Object.values(this.ccgDetails).find((a) => a.regionCode === regionCode)?.regionName;
    }

    getCcgDetail (areaCode: string, detail: keyof CcgMapping): string | number {
        return this.ccgDetails[areaCode][detail];
    }

    getCcgDetailGroupedByAreaCodes (detail: keyof CcgMapping): Record<string, number> {
        const data: Record<string, number> = {};
        this.areaCodes.forEach((areaCode) => {
            data[areaCode] = Number(this.getCcgDetail(areaCode, detail));
        });
        return data;
    }

    getCcgCodeFromAreaCode (areaCode: string): string {
        return this.ccgDetails[areaCode]?.ccgCode;
    }

    getAreaNameFromAreaCode (areaCode: string, areaType: 'ccg' | 'stp' | 'trust'): string {
        if (areaType === 'ccg') {
            return this.ccgDetails[areaCode]?.ccgName;
        }
        const stpCode = this.getStpCodeFromAreaCode(areaCode);
        return Object.values(this.stpDetails).find((a) => a.stpCode === stpCode)!.stpName;
    }

    getTrustNameFromTrustId (trustId: string): string | undefined {
        return this.trustDetailsByTrust[trustId]?.providerName;
    }

    getValueColour (f: number, extents: ExtentsFunction, ...extentParams: unknown[]): string | undefined {
        return (f !== undefined && f !== null ? d3.scaleLinear()
            .domain(extents(...extentParams))
            .interpolate(d3.interpolateHcl as never)
            .range(this.colourRange as never)(f)?.toString() : '');
    }

    extentsFunction (data: Array<number>, min?: number, max?: number): [number, number, number] {
        let minVal = Number.MAX_SAFE_INTEGER;
        let maxVal = Number.MIN_SAFE_INTEGER;

        let total = 0;
        data.forEach((val) => {
            if (val === null || val === undefined) return;
            total += val;
            if (minVal > val) minVal = val;
            if (maxVal < val) maxVal = val;
        });
        return [max ?? maxVal, total / data.length, min ?? minVal];
    }
}
