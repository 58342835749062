









import {
    AxisLabelsFormatterContextObject,
    Chart, SeriesOptionsType, TooltipFormatterContextObject,
} from 'highcharts';

import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import dropdown from '@/components/dropdown.vue';
import DataService from '@/services/dataService';
import CcgDataType from '@/models/ccgDataTypes';
import dayjs from 'dayjs';
import Speciality from '@/models/speciality';

@Component({ components: { dropdown } })
export default class ProjectionsChart extends Vue {
    @Prop()
    selectedDataType!: CcgDataType;

    @Prop()
    selectedSpeciality!: Speciality;

    @Prop()
    selectedScenario!: string;

    @Prop()
    scenarioName!: string;

    dataService: DataService | null = null;

    hasClicked = false;

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
        this.$nextTick(() => {
            this.chart.reflow();
        });
    }

    get chartData (): Array<Partial<SeriesOptionsType>> {
        if (!this.dataService) return [];

        if (this.selectedDataType === CcgDataType.Unmet) {
            return this.unmetNeeds;
        }

        const data: Array<Partial<SeriesOptionsType>> = [
            {
                name: `${this.dataTypeName} in England`,
                data: this.categories.map((date) => (this.dataService?.getProjectionsForDate(date, this.selectedDataType, this.selectedScenario, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#005eb8',
                zoneAxis: 'x',
                zones: [{
                    value: 36,
                }, {
                    dashStyle: 'Dash',
                    className: 'future',
                }],
            }];

        return data;
    }

    get unmetNeeds (): Array<Partial<SeriesOptionsType>> {
        const hiddenDataType = this.selectedDataType.replace('Unmet', 'Hidden');
        const dataType = this.selectedDataType.replace('Unmet', 'Waiting');
        return [
            {
                name: 'Hidden needs in England',
                data: this.categories.map((date) => (this.dataService?.getProjectionsForDate(date, hiddenDataType as CcgDataType, this.selectedScenario, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#919EA8',
                zoneAxis: 'x',
                zones: [{
                    value: 36,
                }, {
                    dashStyle: 'Dash',
                    className: 'future',
                }],
            },
            {
                name: 'Waiting list in England',
                data: this.categories.map((date) => (this.dataService?.getProjectionsForDate(date, dataType as CcgDataType, this.selectedScenario, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#005eb8',
                zoneAxis: 'x',
                zones: [{
                    value: 36,
                }, {
                    dashStyle: 'Dash',
                    className: 'future',
                }],
            },
        ];
    }

    get categories (): Array<string> {
        return this.dataService!.projectionDates;
    }

    get isMobile (): boolean {
        return window.innerWidth < 900;
    }

    get dataTypeName (): string {
        return this.dataService!.getDataTypeLabel(this.selectedDataType);
    }

    get chartText (): string {
        return `${this.scenarioName} for ${this.dataTypeName} in England for ${this.selectedSpeciality}`;
    }

    get yAxisTitle (): string {
        return 'Total';
    }

    get chart (): Chart {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    formatDate (date: string): string {
        return dayjs(date).format('MMM YYYY');
    }

    reflow (): void {
        this.chart.reflow();
    }

    get chartOptions (): unknown {
        if (!this.dataService) return {};
        const formatDate = this.formatDate;
        return {
            chart: {
                type: 'areaspline',
                backgroundColor: 'transparent',
                // height: this.areaType === 'trust' ? ((window.innerHeight - 70 - 120 - 64) / 1.4) : ((window.innerHeight - 70 - 120 - 64) / 2),
                // width: (window.innerWidth / 2) - 60 - 32,
            },
            title: {
                text: this.chartText,
                verticalAlign: 'bottom',
            },
            xAxis: {
                categories: this.categories,
                labels: {
                    style: {
                        fontFamily: 'Open Sans',
                    },
                    formatter (a: AxisLabelsFormatterContextObject): string {
                        return `${formatDate(a.value as string)}`;
                    },
                },
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },

            },
            yAxis: {
                labels: {
                    style: {
                        fontFamily: 'Open Sans',
                    },
                    formatter (a: { value: number }): string {
                        return `${(a.value.toLocaleString())}`;
                    },
                },
                title: {
                    text: this.yAxisTitle,
                },
            },

            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },

                        },
                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },
                    },
                },
                areaspline: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `${s}<br/><span style="color:${point.color}">\u25CF</span>
                        ${point.series.name}: <b>${(Math.round(point.y * 100) / 100).toLocaleString()}</b>`, `<i>${formatDate(self.x.toString())}</i>`);
                },
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: [
                '#005eb8',
                '#005EB8',
                '#666',
                '#99C7EB',
                '#61093f',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            series: this.chartData || [],
        };
    }
}
