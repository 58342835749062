











import CcgDataType from '@/models/ccgDataTypes';
import { Ranking } from '@/models/ranking';
import Speciality from '@/models/speciality';
import TrustDataType from '@/models/trustDataTypes';
import DataService from '@/services/dataService';
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import AreaStatsTable from './charts/area-stats-table.vue';

@Component({ components: { AreaStatsTable } })
export default class Rankings extends Vue {
    @Prop()
    dataType!: CcgDataType | TrustDataType;

    @Prop()
    areaType!: 'ccg' | 'trust' | 'stp';

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedDate!: string;

    @Prop()
    selectedSpeciality!: Speciality;

    dataService: DataService | null = null;

    get ranking (): number {
        return this.selectedArea ? this.rankings.findIndex((a) => a.code === this.selectedArea) : 0;
    }

    get rankings (): Array<Ranking> {
        if (this.areaType === 'ccg') {
            const data = this.dataService!.getRankedCcgData(this.dataType as CcgDataType, this.selectedDate, this.selectedSpeciality);
            if (this.dataService?.isRegion(this.selectedArea)) {
                return this.dataService.getAllCcgsForRegion(this.selectedArea)
                    .map((ccg) => data.find((a) => a.code === ccg.ccgCode)!)
                    .sort((a, b) => a!.ranking - b!.ranking);
            }
            return data;
        }
        if (this.areaType === 'stp') {
            const data = this.dataService!.getRankedStpData(this.dataType as CcgDataType, this.selectedDate, this.selectedSpeciality);
            return data;
        }
        const data = this.dataService!.getRankedTrustData(this.dataType as TrustDataType, this.selectedDate, this.selectedSpeciality);
        if (this.dataService?.isRegion(this.selectedArea)) {
            return this.dataService.getAllTrustsForRegion(this.selectedArea)
                .map((ccg) => data.find((a) => a.code === ccg.providerCode)!)
                .sort((a, b) => a!.ranking - b!.ranking);
        }
        return data;
    }

    get rankingsTable (): Array<Ranking> {
        const areas = [];
        if (!this.selectedArea || this.dataService?.isRegion(this.selectedArea)) {
            return this.rankings;
        }
        const r = Math.min(Math.max(this.ranking - 5, 0), this.rankings.length - 9);
        let count = 10;
        if (this.ranking > 5) {
            count -= 1;
            areas.push({
                ...this.rankings[0], first: true,
            });
        }
        for (let i = r; i < r + count; i += 1) {
            if (this.rankings && this.rankings[i]) {
                areas.push(this.rankings[i]);
            }
        }
        if (this.rankings.length && areas[areas.length - 1].code !== this.rankings[this.rankings.length - 1].code) {
            areas.splice(areas.length - 1, 1);
            areas.push({ ...this.rankings[this.rankings.length - 1], last: true });
        }
        return areas;
    }

    get top10 (): Array<Ranking> {
        return this.rankings.slice(0, 10);
    }

    get bottom10 (): Array<Ranking> {
        return this.rankings.slice(this.rankings.length - 10, this.rankings.length);
    }

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
    }

    selectTrust (trust: string): void {
        this.$emit('trustSelected', trust);
    }

    selectCcg (trust: string): void {
        this.$emit('ccgSelected', trust);
    }
}
