







import {
    Component, Vue, Prop,
} from 'vue-property-decorator';

@Component
export default class AreaStatsTable extends Vue {
    @Prop()
    data!: Array<number>;

    @Prop()
    colour!: string;

    @Prop()
    smallChart!: boolean;

    height = 20;

    get width (): number {
        return this.smallChart ? 40 : 100;
    }

    get path (): string {
        const max = Math.max(...this.data) + 1;
        let path = 'M';
        this.data.forEach((d, i) => { path += `${(this.width / this.data.length) * i} ${this.height - (this.height / max) * d} L`; });

        return `${path}200 20L 0 20Z`;
    }
}
