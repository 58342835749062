



























import CcgDataType from '@/models/ccgDataTypes';
import Speciality from '@/models/speciality';
import TrustDataType from '@/models/trustDataTypes';
import DataService from '@/services/dataService';
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import MiniLine from './mini-line.vue';

@Component({ components: { MiniLine } })
export default class AreaStatsTable extends Vue {
    @Prop()
    dataType!: CcgDataType | TrustDataType;

    @Prop()
    areaType!: 'ccg' | 'trust' | 'stp';

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedSpeciality!: Speciality;

    @Prop()
    selectedDate!: string;

    @Prop()
    rankings!: Array<{ ranking: number; code: string; name?: string | undefined; value: number; }>

    dataService: DataService | null = null;

    get extents (): [number, number, number ] {
        if (!this.dataService) return [0, 0, 0];
        if (this.areaType === 'trust') return this.dataService.getMinMaxForTrustDataType(this.dataType as TrustDataType, this.selectedSpeciality).reverse() as [number, number, number];
        if (this.areaType === 'stp') return this.dataService.getMinMaxForStp(this.dataType as CcgDataType, this.selectedSpeciality).reverse() as [number, number, number];
        return this.dataService.getMinMaxForCcgDataType(this.dataType as CcgDataType, this.selectedSpeciality).reverse() as [number, number, number];
    }

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
    }

    getColour (val: number): string {
        if (!this.dataService) return '#444';
        return this.dataService.getValueColour(val, this.extentsFunction) ?? '#444';
    }

    extentsFunction (): [number, number, number] {
        if (!this.dataService) return [0, 0, 0];
        return this.extents;
    }

    selectArea (area: { code: string; }): void {
        if (this.areaType === 'ccg' || this.areaType === 'stp') {
            this.$emit('selectCcg', area.code);
        } else {
            this.$emit('selectTrust', area.code);
        }
    }

    get tableData (): Array<{code: string; colour: string; name?: string; medianWaitTime: number | undefined | null; medianWaitTimeData: Array<number | undefined>}> {
        return this.rankings!.map((area) => ({
            code: area.code,
            colour: this.getColour(this.dataService!.getAreaTotalForDate(this.areaType, area.code, this.selectedDate, this.dataType, this.selectedSpeciality)),
            name: area.name,
            ranking: area.ranking,
            medianWaitTime: area.value,
            medianWaitTimeData: this.dataService!.dates.map((date) => this.dataService!.getAreaTotalForDate(this.areaType, area.code, date, this.dataType, this.selectedSpeciality)),
        }));
    }

    formatNumber (number?: number): string {
        if (number === null || number === undefined) return '';
        if (this.dataType === CcgDataType.Hidden || this.dataType === CcgDataType.Unmet || this.dataType === CcgDataType.UnmetPer100000 || this.dataType === CcgDataType.HiddenPer100000) {
            return Math.round(number).toLocaleString();
        }
        return number.toLocaleString();
    }
}
