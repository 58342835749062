import { render, staticRenderFns } from "./Scatter.vue?vue&type=template&id=04f208a0&scoped=true&"
import script from "./Scatter.vue?vue&type=script&lang=ts&"
export * from "./Scatter.vue?vue&type=script&lang=ts&"
import style0 from "./Scatter.vue?vue&type=style&index=0&id=04f208a0&lang=scss&scoped=true&"
import style1 from "./Scatter.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f208a0",
  null
  
)

export default component.exports