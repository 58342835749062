









import CcgDataType from '@/models/ccgDataTypes';
import Speciality from '@/models/speciality';
import TrustDataType from '@/models/trustDataTypes';
import DataService from '@/services/dataService';
import dayjs from 'dayjs';
import {
    AxisLabelsFormatterContextObject,
    Chart, Point, PointInteractionEventObject, TooltipFormatterContextObject,
} from 'highcharts';

import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

@Component
export default class SpecialitiesChart extends Vue {
    @Prop()
    dataType!: TrustDataType | CcgDataType;

    @Prop()
    selectedDate!: string;

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedSpeciality!: Speciality;

    @Prop()
    selectedDataType!: CcgDataType | TrustDataType;

    @Prop()
    areaType!: 'trust' | 'ccg' | 'stp';

    dataService: DataService | null = null;

    hasClicked = false;

    hideTotal = true;

    systemIsDeselecting = false;

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
        this.$nextTick(() => {
            if (this.chart) this.chart!.redraw();
        });
    }

    get chartData (): Array<{ name: string; y: number }> {
        if (!this.dataService) return [];

        const output: Array<{ name: string; y: number }> = [];

        if (this.areaType === 'trust') {
            this.categories.forEach((category) => {
                output.push({
                    name: category,
                    y: this.dataService?.getDataGroupedByTrust(this.selectedDataType as TrustDataType, this.selectedDate, category)?.[this.selectedArea ?? 'Percent'] ?? 0,
                });
            });
            return output;
        }
        if (this.areaType === 'ccg' || this.selectedArea === 'stp') {
            let area = this.selectedArea ?? 'Total';
            if (this.selectedArea === 'stp') {
                area = 'Total';
            }
            this.categories.forEach((category) => {
                output.push({
                    name: category,
                    y: this.dataService?.getDataGroupedByCcg(this.selectedDataType as CcgDataType, this.selectedDate, category)?.[area] ?? 0,
                });
            });
            return output;
        }
        this.categories.forEach((category) => {
            output.push({
                name: category,
                y: this.dataService?.getDataGroupedByStp(this.selectedDataType as CcgDataType, this.selectedDate, category)?.[this.selectedArea ?? 'Total'] ?? 0,
            });
        });
        return output;
    }

    get areaName (): string {
        if (!this.selectedArea || !this.dataService) return 'England';
        if (this.areaType === 'stp') {
            return this.dataService.getAreaNameFromStp(this.selectedArea) ?? '';
        }
        return this.dataService?.getAreaNameFromCcg(this.selectedArea) ?? this.dataService?.getRegionNameFromRegionCode(this.selectedArea) ?? this.selectedArea;
    }

    @Watch('selectedSpeciality')
    selectedSpecialityChanged (): void {
        if (this.selectedSpeciality !== Speciality.Total) {
            this.hasClicked = true;
        }

        this.$nextTick(() => {
            this.systemIsDeselecting = true;
            if (this.chart?.series[0]) {
                const index = this.chart!.series[0].points.map((x) => x.name).indexOf(this.selectedSpeciality);
                if (index !== -1) this.chart!.series[0].points[index].select(true, false);
                else this.chart.series[0].points.forEach((point) => point.select(false));
            }
            this.systemIsDeselecting = false;
            if (this.chart) this.chart.redraw(false);
        });
    }

    get categories (): Array<Speciality> {
        return [
            Speciality.Dermatology,
            Speciality.Rheumatology,
            Speciality.Gastroenterology,
            Speciality.Orthopaedics,

        ];
    }

    get chartText (): string {
        return `${this.dataService?.getDataTypeLabel(this.dataType)} in ${this.areaName} for ${this.formatDate(this.selectedDate)}`;
    }

    formatDate (date: string): string {
        return dayjs(date).format('MMM YYYY');
    }

    get chart (): Chart | null {
        if (!this.$refs.chart) return null;
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get chartOptions (): unknown {
        const nextTick = this.$nextTick;
        const selectedSpeciality = this.selectedSpeciality;
        return {
            title: {
                text: this.chartText,
                verticalAlign: 'bottom',
            },
            chart: {
                type: 'bar',
                backgroundColor: 'transparent',
                // height: (window.innerHeight - 70 - 120 - 64) / 2,
                // width: (window.innerWidth / 2) - 60 - 32,
                alignTicks: false,
            },
            xAxis: {
                labels: {
                    style: {
                        fontFamily: 'Open Sans',
                        whiteSpace: 'nowrap',
                    },
                    overflow: 'justify',
                    formatter (): string {
                        const self = this as unknown as AxisLabelsFormatterContextObject;
                        const label = self.axis.defaultLabelFormatter.call(self);
                        if (label === selectedSpeciality) return `<b>${label}</b>`;
                        nextTick(() => {
                            const els = document.getElementsByClassName('highcharts-axis-labels highcharts-xaxis-labels');
                            if (els.length) {
                                const el = (els[0]as HTMLElement);
                                for (let i = 0; i < el.children.length; i += 1) {
                                    if (el.children[i].innerHTML.replace('&amp;', '&') === label) {
                                        (el.children[i] as HTMLElement).style.cursor = 'pointer';
                                        (el.children[i] as HTMLElement).onclick = () => {
                                            self.chart.series[0].points[self.pos].select(true, false);
                                        };
                                    }
                                }
                            }
                        });
                        return label;
                    },
                },
                type: 'category',
                categories: this.categories,
                crosshair: {
                    color: 'rgba(0, 0, 0, 0)',

                },
                clickOnCrosshair (e: unknown, p: Point) {
                    p.select(true, false);
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Open Sans',
                    },
                },
                labels: {
                    rotation: 0,
                    style: {
                        fontFamily: 'Open Sans',
                    },
                    formatter (a: AxisLabelsFormatterContextObject): string {
                        return `${(a.value.toLocaleString())}`;
                    },
                },
            },
            plotOptions: {
                series: {
                    states: {
                        select: {
                            color: '#ED8B00',
                        },
                    },
                    allowPointSelect: true,
                    marker: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            select: (e: PointInteractionEventObject) => {
                                if (!this.systemIsDeselecting) {
                                    this.$nextTick(() => {
                                        this.$emit('specialitySelected', (e!.target! as unknown as { name: string }).name);
                                    });
                                }
                            },
                            unselect: (e: PointInteractionEventObject) => {
                                const treatmentKey = (e!.target! as unknown as { name: string }).name;
                                if (this.selectedSpeciality === treatmentKey) {
                                    if (!this.systemIsDeselecting) {
                                        this.$emit('specialitySelected', Speciality.Total);
                                    }
                                }
                            },

                        },
                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart!.tooltip.hide(); return false; },
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `<span style="color:${point.color}">\u25CF</span>
                        ${s} <b> ${Math.round(point.y).toLocaleString()}</b>`, `<i>${self.points![0].key.toString()}<i>`);
                },
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: ['#005eb8',
                '#005EB8'],
            series: [{
                name: 'Specialities',
                type: 'bar',
                data: this.chartData || [],
            }],
        };
    }
}
