/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts, { Chart, XAxisCrosshairLabelFormatterCallbackFunction } from 'highcharts';
import annotations from 'highcharts/modules/annotations';
import drilldown from 'highcharts/modules/drilldown';

Highcharts.Pointer.prototype.reset = function disableMouseOut () {
    const newLocal: { chart: Chart } = this as unknown as { chart: Chart };
    newLocal.chart.tooltip.hide();
    return undefined;
};

Highcharts.wrap(Highcharts.Axis.prototype, 'drawCrosshair', function drawCrosshair (this: { cross: unknown; options: { clickOnCrosshair: (...props: unknown[]) => void }; chart: Highcharts.Chart }, proceed) {
    const axis = this as { cross: any; options: { clickOnCrosshair: (...props: unknown[]) => void }; chart: Highcharts.Chart };
    const wasRendered = !!axis.cross;
    // eslint-disable-next-line prefer-rest-params
    proceed.apply(axis, Array.prototype.slice.call(arguments, 1));
    if (!wasRendered && axis.cross && axis.options.clickOnCrosshair) {
        axis.cross.css({
            pointerEvents: 'auto',
            cursor: 'pointer',
        });

        axis.cross.on('click', (e: XAxisCrosshairLabelFormatterCallbackFunction) => {
            const point = axis.chart.hoverPoint;
            axis.options.clickOnCrosshair(e, point);
        });
    }
});

annotations(Highcharts);
drilldown(Highcharts);
Vue.use(HighchartsVue, { Highcharts });
