










import {
    AxisLabelsFormatterContextObject,
    Chart, PointerEventObject, SeriesOptionsType, TooltipFormatterContextObject,
} from 'highcharts';

import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import dropdown from '@/components/dropdown.vue';
import DataService from '@/services/dataService';
import TrustDataType from '@/models/trustDataTypes';
import CcgDataType from '@/models/ccgDataTypes';
import dayjs from 'dayjs';
import Speciality from '@/models/speciality';

@Component({ components: { dropdown } })
export default class HighchartsLineChart extends Vue {
    @Prop()
    selectedDataType!: TrustDataType | CcgDataType;

    @Prop()
    selectedSpeciality!: Speciality;

    @Prop()
    selectedDate!: string;

    @Prop()
    selectedArea!: string;

    @Prop()
    areaType!: 'ccg' | 'trust' | 'stp';

    dataService: DataService | null = null;

    hasClicked = false;

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
        this.$nextTick(() => {
            this.selectYear();
            this.chart.reflow();
        });
    }

    get chartData (): Array<Partial<SeriesOptionsType>> {
        if (!this.dataService) return [];
        if (this.selectedDataType === TrustDataType.CancerVsElectiveNHSTargets) {
            return this.referralTimes;
        }
        if (this.selectedDataType === CcgDataType.Unmet || this.selectedDataType === CcgDataType.UnmetPer100000) {
            return this.unmetNeeds;
        }

        let data: Array<Partial<SeriesOptionsType>> = [
            {
                name: `${this.dataTypeName} in England`,
                data: this.categories.map((date) => (this.dataService?.getNationalTotalForDate(this.areaType, date, this.selectedDataType, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#005eb8',
            }];

        if (this.selectedArea) {
            data = [];
            data.push({
                name: `${this.dataTypeName} in ${this.areaName}`,
                data: this.categories.map((date) => (this.dataService?.getAreaTotalForDate(this.areaType, this.selectedArea, date, this.selectedDataType, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#005eb8',
            });
        }

        return data;
    }

    get unmetNeeds (): Array<Partial<SeriesOptionsType>> {
        const hiddenDataType = this.selectedDataType.replace('Unmet', 'Hidden');
        const dataType = this.selectedDataType.replace('Unmet', 'Waiting');
        const label = this.selectedDataType.indexOf('100000') ? ' per 100,000' : '';
        return [
            {
                name: `Hidden needs${label} in ${this.areaName}`,
                data: this.categories.map((date) => (this.dataService?.getAreaTotalForDate(this.areaType, this.selectedArea ?? 'Total', date, hiddenDataType as CcgDataType, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#919EA8',
            },
            {
                name: `Waiting list${label} in ${this.areaName}`,
                data: this.categories.map((date) => (this.dataService?.getAreaTotalForDate(this.areaType, this.selectedArea ?? 'Total', date, dataType as CcgDataType, this.selectedSpeciality) ?? 0)),
                type: 'areaspline',
                lineWidth: 3,
                color: '#005eb8',
            },
        ];
    }

    get referralTimes (): Array<Partial<SeriesOptionsType>> {
        return [{
            name: `% Admitted patients seen within 18 weeks in ${this.areaName} ${this.selectedSpeciality !== Speciality.Total ? `(${this.selectedSpeciality})` : ''}`,
            data: this.categories.map((date) => (this.dataService?.getAreaTotalForDate(this.areaType, this.selectedArea || 'Percent', date, TrustDataType.PercentWithin18WeeksAdmitted, this.selectedSpeciality) ?? 0)),
            type: 'line',
            lineWidth: 3,
            color: '#1c74bc',
        },
        {
            name: `% Non-admitted patients seen within 18 weeks in ${this.areaName} ${this.selectedSpeciality !== Speciality.Total ? `(${this.selectedSpeciality})` : ''}`,
            data: this.categories.map((date) => (this.dataService?.getAreaTotalForDate(this.areaType, this.selectedArea || 'Percent', date, TrustDataType.PercentWithin18WeeksNonAdmitted, this.selectedSpeciality) ?? 0)),
            type: 'line',
            lineWidth: 3,
            color: '#199ed9',
        },
        {
            name: `% of cancer patients seeing a specialist within 14 days in ${this.areaName}`,
            data: this.categories.map((date) => (this.dataService?.getAreaTotalForDate(this.areaType, this.selectedArea || 'Percent', date, TrustDataType.PercentWithin14Days, Speciality.Total) ?? 0)),
            type: 'line',
            lineWidth: 3,
            color: '#aaa',
        }];
    }

    get categories (): Array<string> {
        return this.dataService!.dates;
    }

    get isMobile (): boolean {
        return window.innerWidth < 900;
    }

    get dataTypeName (): string {
        return this.dataService!.getDataTypeLabel(this.selectedDataType);
    }

    get areaName (): string {
        if (!this.selectedArea || !this.dataService) return 'England';
        if (this.areaType === 'ccg') {
            return this.dataService.getAreaNameFromCcg(this.selectedArea) ?? this.dataService?.getRegionNameFromRegionCode(this.selectedArea) ?? this.selectedArea;
        }
        if (this.areaType === 'stp') {
            return this.dataService.getAreaNameFromStp(this.selectedArea) ?? '';
        }
        return this.dataService.getTrustNameFromTrustId(this.selectedArea) ?? this.dataService?.getRegionNameFromRegionCode(this.selectedArea) ?? this.selectedArea;
    }

    get chartText (): string {
        if (this.selectedDataType === TrustDataType.PercentWithin18WeeksAdmitted || this.selectedDataType === TrustDataType.PercentWithin18WeeksNonAdmitted || this.selectedDataType === TrustDataType.PercentWithin14Days) {
            return `% referrals in ${this.areaName}`;
        }
        return `${this.dataTypeName} in ${this.areaName}`;
    }

    get yAxisTitle (): string {
        if (this.selectedDataType === TrustDataType.PercentWithin18WeeksAdmitted || this.selectedDataType === TrustDataType.PercentWithin18WeeksNonAdmitted || this.selectedDataType === TrustDataType.PercentWithin14Days) {
            return 'proportion of target achieved';
        }
        if (this.selectedDataType === TrustDataType.CancerVsElectiveNHSTargets) {
            return 'proportion of target achieved';
        }
        return 'Total';
    }

    selectYear () : void {
        if (this.chart && this.chart.series.length && this.chart.series[0].points.length) {
            const e: PointerEventObject = { target: this.chart.series[0].points[0] } as unknown as PointerEventObject;
            if (this.chart.series[0].points[0].y != null) {
                const point = this.chart.series[0].points.find((a) => a.category === this.selectedDate);
                if (point) point.onMouseOver(e);
                else this.chart.series[0].points[0].onMouseOver(e);
            }
        }
        this.chart.tooltip.hide();
    }

    gotoProjections (): void {
        this.$router.push('/projections');
    }

    @Watch('selectedDate')
    selectedDateChanged (): void {
        if (!this.dataService || !this.selectedDate) return;
        if (this.chart) this.chart.redraw(false);
    }

    get chart (): Chart {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    selectDate (date: string): void {
        this.$emit('dateSelected', date);
    }

    formatDate (date: string): string {
        return dayjs(date).format('MMM YYYY');
    }

    @Watch('loading')
    @Watch('chartData')
    loadingChanged (): void {
        this.$nextTick(() => {
            this.chart.reflow();
            this.selectYear();
        });
    }

    reflow (): void {
        this.chart.reflow();
    }

    get chartOptions (): unknown {
        if (!this.dataService) return {};
        const formatDate = this.formatDate;
        const selectDate = this.selectDate;
        const areaType = this.areaType;
        return {
            chart: {
                type: 'areaspline',
                backgroundColor: 'transparent',
                // height: this.areaType === 'trust' ? ((window.innerHeight - 70 - 120 - 64) / 1.4) : ((window.innerHeight - 70 - 120 - 64) / 2),
                // width: (window.innerWidth / 2) - 60 - 32,
            },
            title: {
                text: this.chartText,
                verticalAlign: 'top',
            },
            xAxis: {
                categories: this.categories,
                labels: {
                    style: {
                        fontFamily: 'Open Sans',
                    },
                    formatter (a: AxisLabelsFormatterContextObject): string {
                        return `${formatDate(a.value as string)}`;
                    },
                },
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },

            },
            yAxis: {
                labels: {
                    style: {
                        fontFamily: 'Open Sans',
                    },
                    formatter (a: { value: number }): string {
                        return `${(a.value.toLocaleString())}`;
                    },
                },
                title: {
                    text: this.yAxisTitle,
                },
                max: this.areaType === 'trust' ? 1 : null,
            },

            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            mouseOver: (e: { target: { category: string }}) => {
                                selectDate(e.target.category);
                            },
                            mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },

                        },
                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },
                    },
                },
                areaspline: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `${s}<br/><span style="color:${point.color}">\u25CF</span>
                        ${point.series.name}: <b>${
    (areaType === 'trust' ? (Math.round(point.y * 100) / 100) : Math.round(point.y))
        .toLocaleString()}</b>`, `<i>${formatDate(self.x.toString())}</i>`);
                },
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: this.areaType === 'trust',
            },
            credits: {
                enabled: false,
            },
            colors: [
                '#005eb8',
                '#005EB8',
                '#666',
                '#99C7EB',
                '#61093f',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            series: this.chartData || [],
        };
    }
}
