





































import CcgDataType from '@/models/ccgDataTypes';
import Speciality from '@/models/speciality';
import DataService from '@/services/dataService';
import { Component, Vue } from 'vue-property-decorator';
import ProjectionsChart from '@/components/charts/projections-chart.vue';
import { DropdownItem } from '@/models/dropdownItem';
import Dropdown from '@/components/dropdown.vue';

@Component({
    components: { ProjectionsChart, Dropdown },
})
export default class Projections extends Vue {
    dataService: DataService | null = null;

    selectedSpeciality = Speciality.Total;

    selectedScenario = '1';

    selectedDataType = CcgDataType.Waiting;

    dropdownOptions: Array<DropdownItem> = [
        {
            name: 'Waiting list', value: CcgDataType.Waiting, icon: 'fa-calendar',
        },
        {
            name: 'Waiting list - hidden needs', value: CcgDataType.Hidden, icon: 'fa-calendar', secondaryIcon: 'fa-eye-slash',
        },
        {
            name: 'Waiting list - unmet needs', value: CcgDataType.Unmet, icon: 'fa-calendar', secondaryIcon: 'fa-exclamation',
        },
    ]

    specialityOptions: Array<DropdownItem> = [
        { name: 'All', value: Speciality.Total, icon: 'fa-star-of-life' },
        { name: 'Dermatology', value: Speciality.Dermatology, icon: 'fa-hand-dots' },
        { name: 'Rheumatology', value: Speciality.Rheumatology, icon: 'fa-skeleton' },
        { name: 'Gastroenterology', value: Speciality.Gastroenterology, icon: 'fa-stomach' },
        { name: 'Orthopaedics', value: Speciality.Orthopaedics, icon: 'fa-crutches' },
    ]

    scenarios: Record<number, string> = {
        1: 'No uplift to capacity',
        2: 'Symmetric uplift',
        3: 'Asymmetric uplift',
        4: 'Workforce-adjusted uplift',
    }

    async mounted (): Promise<void> {
        this.dataService = await DataService.get();
    }

    get loaded (): boolean {
        return this.dataService?.loaded ?? false;
    }
}
